import React from "react";
import './OutsideList.css';

class OutsideList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  renderBoolean(item) {
    if (item.status === 100) {
      return true;
    }
  }

  chosenIdActive(id){
    if(id === this.state.chosenId){
      return "activeId"
    } else {
      return ""
    }
  }

  chooseItem(i) {
    if(this.state.chosenId !== i.id){
      this.setState({chosenId: i.id});
      this.props.chosenFunc(i);
    } else{
      this.setState({chosenId: ""});
      this.props.chosenFunc(null);
    }
  }

  render() {
    return (
      <div className="outsideList">
        <div>
          <div className="pakkeListPage">
            <div className="sortBox pakkeSort">
              <div>
                <div className="utkjorbox nr">NR</div>
                <div className="utkjorbox name pakke">NAVN</div>
                <div className="utkjorbox telephone pakke">TELEFON</div>
                <div className="utkjorbox order pakke">ORDRE</div>
                <div className="utkjorbox status sortStatus">TRANSPORT</div>
                <div className="utkjorbox who">LAGT TIL</div>
              </div>
            </div>
            <div className="pakkeList">
              {this.props.items.map(item => {
                if (this.renderBoolean(item)) {
                  return (
                    <li
                      className={"listbox1 outside  " + this.chosenIdActive(item.id)}
                      id={item.id}
                      key={item.id}
                    >
                      <div className="utkjorbox nr">{item.count}</div>
                      <div className="utkjorbox name pakke">{item.name}</div>
                      <div className="utkjorbox telephone pakke">
                        {item.telephone}
                      </div>
                      <div className="utkjorbox order pakke">
                        {item.order}
                      </div>
                      <div className="utkjorbox status pakke">
                        Ja
                      </div>
                      <div className="utkjorbox who pakke">
                        {item.date}
                      </div>
                      <button
                        className={
                          "sendBtn pakkeStatus "}
                        onClick={() => this.chooseItem(item)}
                      >
                        Velg
                      </button>
                    </li>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OutsideList;
