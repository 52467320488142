import React, { Component } from "react";
import "./TilbudsVarer.css";
import firebase from "./firebase.js";

export default class TilbudsVarer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      restItems: []
    };
  }

  componentDidMount() {
    this.retrievePublicData();
  }

  retrievePublicData() {
    const itemsRef = firebase.database().ref("/restItems");

    itemsRef.on("value", snapshot => {
      let items = snapshot.val();

      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          name: items[item].name,
          number: items[item].number,
          unit: items[item].unit,
          count: items[item].count,
          price: items[item].price,
          comment: items[item].comment,
          kolli: items[item].kolli,
          damage: items[item].damage,
          creation: items[item].creation,
          category: items[item].category
        });
      }
      newState.sort(function(a, b) {
        return a.count - b.count;
      });
      this.setState({
        restItems: newState
      });
    });
  }
  render() {
    return (
      <div>
        <h1>Tilbudsvarer</h1>
        <table>
          <tr>
            <th>RefNr</th>
            <th>Kategori</th>
            <th>Navn</th>
            <th>Pris</th>
            <th>Antall</th>
            <th>Enhet</th>
            <th>Skade</th>
          </tr>
          {this.state.restItems.map(item => {
            return (
              <tr id={item}>
                <td>{item.count}</td>
                <td>{item.category}</td>
                <td>{item.name}</td>
                <td>{item.price},-</td>
                <td>{item.number}</td>
                <td>{item.unit}</td>
                <td>{item.damage === true ? "Ja" : "Nei"}</td>
              </tr>
            );
          })}
        </table>
      </div>
    );
  }
}
