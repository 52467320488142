import swal from 'sweetalert2';

let backgroundCSS = '#344861';

const swalWithBootstrapButtons = swal.mixin({
  confirmButtonClass: 'removebtnPopupJA',
  cancelButtonClass: 'removebtnPopupNEi',
  buttonsStyling: false,
  background: backgroundCSS
});

const swalAlert = swal.mixin({
  background: backgroundCSS,
  buttonsStyling: false,
  type: 'info',
  confirmButtonClass: 'removebtn'
});

const swalPrompt = swal.mixin({
  background: backgroundCSS,
  buttonsStyling: false,
  type: 'info',
  confirmButtonClass: 'removebtn'
});

const Toast = swal.mixin({
  background: '#3a4f69',
  customClass: 'swal-wide',
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000
})

export { swalWithBootstrapButtons, swalAlert, swalPrompt, Toast };
