import React from "react";
import "./pakke.css";
import firebase, { auth } from "./firebase.js";
import { Dropdown } from "semantic-ui-react";
import { Checkbox, Radio, RadioGroup } from "react-icheck";
import OutsideInput from "./OutsideInput.js";
import OutsideList from "./OutsideList.js";
import printlabel from "./printlabel.js";
import Modal from "react-modal";
import "icheck/skins/all.css";
import swal from "sweetalert2";
import { swalWithBootstrapButtons, swalPrompt } from "./swalPopups.js";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

const searchOptions = {
  componentRestrictions: { country: "no" }
};

//import printlabelV from './printlabel-v.js';
const Dymo = require("dymojs"),
  dymo = new Dymo();
var $ = require("jquery");

const modalStyle = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "25px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

class Pakke extends React.Component {
  constructor() {
    super();

    this.state = {
      name: "",
      telephone: "",
      order: "",
      date: "",
      comment: "",
      count: "",
      delivery: "option1",
      address: "",
      drive: "Ballstad",
      disabledState: true,
      user: null,
      sms: true,
      state: "",
      items: [],
      le: false,
      logItems: [],
      sort: 0,
      modalIsOpen: false,
      addDrive: "Ballstad",
      addAddress: "",
      addSms: false,
      pushId: "",
      pro: false,
      printer: "option2",
      project: "",
      lat: "",
      lng: "",
      outside: false,
      chosen: null
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.submitModal = this.submitModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDropdown = this.handleDropdown.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handlePlacesChange = this.handlePlacesChange.bind(this);
    this.modalHandleSelect = this.modalHandleSelect.bind(this);
    this.modalHandlePlacesChange = this.modalHandlePlacesChange.bind(this);
  }

  handleChange(event) {
    const target = event.target;

    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handlePlacesChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    this.setState({ address: address });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({ lat: latLng.lat, lng: latLng.lng }))
      .catch(error => console.error("Error", error));
  };

  modalHandlePlacesChange = addAddress => {
    this.setState({ addAddress });
  };

  modalHandleSelect = addAddress => {
    geocodeByAddress(addAddress)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({ lat: latLng.lat, lng: latLng.lng }))
      .catch(error => console.error("Error", error));
  };

  handleDropdown(event, data) {
    let temp;
    for (let i in data.options) {
      if (data.value === data.options[i].name) {
        temp = data.options[i].telephone;
      }
    }
    this.setState({
      name: data.value,
      telephone: temp
    });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
      addSms: false,
      addAddress: "",
      address: "",
      addDrive: "Ballstad",
      pushId: "",
      addDate: ""
    });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  handleRadioChange(event, value) {
    if (this.state.delivery === "option1") {
      this.setState({ disabledState: false });
    } else {
      this.setState({ disabledState: true });
    }
    this.setState({ delivery: value });
  }

  submitModal(e) {
    e.preventDefault();
    this.changeToUtkjoring(
      this.state.pushId,
      this.state.addDrive,
      this.state.addAddress.substr(0, this.state.addAddress.indexOf(",")),
      this.state.addSms,
      this.state.addDate
    );
    this.closeModal();
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.name === "") {
      return;
    }
    if (this.state.telephone === "") {
      return;
    }
    if (this.state.drive === "") {
      return;
    }

    let utkjoringRef = firebase.database().ref("utkjoring");
    let countRef = firebase.database().ref("utkjoringcount");
    let pakkeRef = firebase.database().ref("pakke");

    let item;
    let addy = this.state.address.substr(0, this.state.address.indexOf(","));

    if (this.state.delivery === "option1") {
      item = {
        name: this.state.name,
        telephone: this.state.telephone,
        order: this.state.order,
        creation: Date.now(),
        date: this.state.date,
        comment: this.state.comment,
        count: this.state.count,
        le: this.state.le,
        status: 0,
        pri: false,
        pro: this.state.pro,
        project: this.state.project,
        addedBy: this.state.user.displayName
      };
      if (item.date === "") {
        item.date = "Snarest";
      }
      pakkeRef.push(item);
    } else {
      item = {
        name: this.state.name,
        telephone: this.state.telephone,
        order: this.state.order,
        creation: Date.now(),
        drive: this.state.drive,
        date: this.state.date,
        address: addy,
        lat: this.state.lat,
        lng: this.state.lng,
        comment: this.state.comment,
        count: this.state.count,
        le: this.state.le,
        status: 0,
        pri: false,
        sms: this.state.sms,
        pro: this.state.pro,
        project: this.state.project,
        addedBy: this.state.user.displayName
      };
      if (item.date === "") {
        item.date = "Snarest";
      }
      utkjoringRef.push(item);
    }

    countRef.transaction(function(trans) {
      if (trans === 7999) {
        return 1000;
      } else {
        return trans + 1;
      }
    });
    this.setState({
      name: "",
      telephone: "",
      order: "",
      drive: "Ballstad",
      date: "",
      address: "",
      comment: "",
      disabledState: true,
      delivery: "option1",
      le: false,
      sms: true,
      pro: false,
      project: "",
      lat: "",
      lng: ""
    });
  }

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (user) {
        this.setState({ user });
      }
    });
    this.retrieveData();
  }

  retrieveData() {
    let utkjoringRef = firebase.database().ref("utkjoring");
    let countRef = firebase.database().ref("utkjoringcount");
    let pakkeRef = firebase.database().ref("pakke");
    let pakkeLog = firebase.database().ref("pakkeHistory");
    // let zapier = firebase.database().ref("ZapierItems");

    const proffRef = firebase.database().ref("proffKunder");

    let userId = firebase.auth().currentUser.uid;
    this.setState({
      userId: userId
    });
    let userRef = firebase.database().ref("users/" + userId);

    countRef.on("value", snapshot => {
      let countsnap = snapshot.val();
      this.setState({
        count: countsnap
      });
    });

    userRef.on("value", snapshot => {
      let printSnap = snapshot.val();
      if (printSnap !== null) {
        this.setState({
          printer: printSnap.printer
        });
      } else {
        userRef.set({
          printer: "option2"
        });
      }
    });

    proffRef.on("value", snapshot => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          name: items[item].name,
          telephone: items[item].telephone,
          key: items[item].name,
          value: items[item].name,
          text: items[item].name
        });
      }

      this.setState({
        customers: newState
      });
    });

    utkjoringRef.on("value", snapshot => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          name: items[item].name,
          telephone: items[item].telephone,
          order: items[item].order,
          drive: items[item].drive,
          date: items[item].date,
          address: items[item].address,
          count: items[item].count,
          sms: items[item].sms,
          comment: items[item].comment,
          status: items[item].status,
          person: items[item].person,
          le: items[item].le,
          pri: items[item].pri,
          kolli: items[item].kolli,
          project: items[item].project,
          pro: items[item].pro,
          addedBy: items[item].addedBy,
          creation: items[item].creation
        });
      }
      pakkeRef.on("value", snapshot => {
        let items2 = snapshot.val();
        let pakkeState = [];
        for (let item2 in items2) {
          pakkeState.push({
            id: item2,
            name: items2[item2].name,
            telephone: items2[item2].telephone,
            order: items2[item2].order,
            date: items2[item2].date,
            address: items2[item2].address,
            address2: items2[item2].address2,
            count: items2[item2].count,
            sms: items2[item2].sms,
            comment: items2[item2].comment,
            status: items2[item2].status,
            person: items2[item2].person,
            le: items2[item2].le,
            drive: "",
            pri: items2[item2].pri,
            kolli: items2[item2].kolli,
            project: items2[item2].project,
            addedBy: items2[item2].addedBy,
            creation: items2[item2].creation
          });
        }
        pakkeLog.on("value", snapshot => {
          let items2 = snapshot.val();
          let loggState = [];
          for (let item2 in items2) {
            loggState.push({
              id: item2,
              name: items2[item2].name,
              telephone: items2[item2].telephone,
              order: items2[item2].order,
              date: items2[item2].date,
              address: items2[item2].address,
              count: items2[item2].count,
              sms: items2[item2].sms,
              comment: items2[item2].comment,
              status: items2[item2].status,
              person: items2[item2].person,
              le: items2[item2].le,
              drive: "",
              project: items2[item2].project,
              addedBy: items2[item2].addedBy,
              creation: items2[item2].creation
            });
          }

          let bothState = newState.concat(pakkeState);
          bothState.sort(function(a, b) {
            return b.pri - a.pri || a.count - b.count;
          });
          let colours = this.pakkeCounter(bothState);
          this.setState({
            green: colours[0],
            orange: colours[1],
            blue: colours[2],
            red: colours[3]
          });
          this.setState({
            items: bothState,
            logItems: loggState
          });
        });
      });
    });
  }

  showComment(divid) {
    let div = document.getElementById(divid).children[12];
    let icon = document.getElementById(divid).children[11];
    $(document).ready(function() {
      let jdiv = $(div);
      let jicon = $(icon);
      jdiv.slideToggle(300).css("display", "block");
      jicon.find("svg").toggleClass("fa-minus fa-plus");
    });
  }

  printerCheck() {
    if (this.state.printer === "option1") {
      return "DYMO LabelWriter 450";
    } else if (this.state.printer === "option2") {
      return "DYMO LabelWriter 450 Twin Turbo";
    }
  }

  printer(loc, itemId) {
    let printerString = this.printerCheck();
    let printObj = firebase.database().ref(`/${loc}/${itemId}`);
    printObj.once("value", function(snapshot) {
      let item = snapshot.val();
      let count = item.count;
      let name = item.name;
      let telephone = item.telephone;
      let kolli = item.kolli;

      if (printerString === "DYMO LabelWriter 450") {
        for (let i = 1; i <= kolli; i++) {
          dymo.print(
            printerString,
            printlabel(count, name, telephone, i, kolli, itemId),
            ``
          );
        }
      } else {
        for (let i = 1; i <= kolli; i++) {
          dymo.print(
            printerString,
            printlabel(count, name, telephone, i, kolli, itemId),
            `<LabelWriterPrintParams><TwinTurboRoll>Left</TwinTurboRoll></LabelWriterPrintParams>`
          );
        }
      }
    });
  }

  deleteItem(itemId, loc) {
    let firebaseLoc;
    if (this.driveCheck(loc)) {
      firebaseLoc = "utkjoring";
    } else {
      firebaseLoc = "pakke";
    }
    if (this.state.outside) {
      firebaseLoc = loc;
    }
    firebase
      .database()
      .ref(`/${firebaseLoc}/${itemId}`)
      .remove();
  }

  logItem(itemId, loc) {
    let itemRef = firebase.database().ref(`/${loc}/${itemId}`);
    let historyList = firebase.database().ref(`/pakkeHistory`);
    itemRef.once("value", function(snapshot) {
      historyList.push(snapshot.val());
    });
    firebase
      .database()
      .ref(`/${loc}/${itemId}`)
      .remove();
  }

  assignPerson(itemId, loc, name) {
    firebase
      .database()
      .ref(`/${loc}/${itemId}`)
      .update({ person: name });
  }

  printerHelper(drive) {
    let firebaseLoc;
    if (this.driveCheck(drive)) {
      firebaseLoc = "utkjoring";
    } else {
      firebaseLoc = "pakke";
    }
    return firebaseLoc;
  }

  changeStatus(itemId, drive, currStatus, name) {
    let firebaseLoc;
    let _this = this;
    if (this.driveCheck(drive)) {
      firebaseLoc = "utkjoring";
    } else {
      firebaseLoc = "pakke";
    }
    if (currStatus === 2) {
      if (firebaseLoc === "utkjoring") {
        return this.confirmLog(itemId, name, firebaseLoc);
      }
      return this.logItem(itemId, firebaseLoc);
    }
    if (currStatus === 1) {
      swalPrompt({
        title: "Hvor mange kolli?",
        input: "range",
        inputAttributes: {
          min: 1,
          max: 20,
          step: 1
        },
        inputValue: 1,
        type: "info",
        allowOutsideClick: false
      }).then(function(amount) {
        if (amount) {
          firebase
            .database()
            .ref(`/${firebaseLoc}/${itemId}`)
            .update({
              kolli: parseInt(amount.value, 10)
            });
          _this.printer(firebaseLoc, itemId);
        }
      });
    }
    if (currStatus === 0) {
      let name = this.formatName(this.state.user.displayName);
      this.assignPerson(itemId, firebaseLoc, name);
    }
    let updated = currStatus + 1;
    firebase
      .database()
      .ref(`/${firebaseLoc}/${itemId}`)
      .update({
        status: updated
      });
  }

  formatName(name) {
    let temp = name.split(" ");
    return temp[0] + " " + temp[temp.length - 1].charAt(0) + ".";
  }

  statusRender(status) {
    if (status === 0) {
      return (
        <div>
          <i className="far fa-circle" /> Ikke startet
        </div>
      );
    }
    if (status === 1) {
      return (
        <div>
          <i className="far fa-clock" /> Pågår
        </div>
      );
    }
    if (status === 2) {
      return (
        <div>
          <i className="far fa-check-circle" /> Ferdig
        </div>
      );
    }
    if (status === 100) {
      return (
        <div>
          <i class="fas fa-exclamation" /> Kode 100
        </div>
      );
    }
    return <i class="far fa-circle" />;
  }

  statusClass(status) {
    if (status === 0) {
      return "notPack";
    }
    if (status === 1) {
      return "gettingPack";
    }
    if (status === 2) {
      return "donePack";
    }
  }

  statusButton(status) {
    if (status === 0) {
      return "Pakk";
    }
    if (status === 1) {
      return "Pakket";
    }
    if (status === 2) {
      return "Hentet";
    }
  }

  driveCheck(driveStatus) {
    if (driveStatus === "") {
      return false;
    } else {
      return true;
    }
  }

  pakkeCounter(items) {
    let green = 0;
    let orange = 0;
    let blue = 0;
    let red = 0;

    for (let item in items) {
      if (items[item].status === 2) {
        green++;
      } else {
        orange++;
      }
      if (items[item].le === true) {
        blue++;
      }
      if (items[item].status === 100) {
        red++;
        orange--;
      }
    }
    return [green, orange, blue, red];
  }

  resetStatus(itemId, place) {
    let loc;
    if (this.driveCheck(place)) {
      loc = "utkjoring";
    } else {
      loc = "pakke";
    }
    firebase
      .database()
      .ref(`/${loc}/${itemId}`)
      .update({
        status: 0,
        person: "",
        kolli: ""
      });
  }

  prioritizeItem(itemId, place, currPri) {
    let loc;
    if (this.driveCheck(place)) {
      loc = "utkjoring";
    } else {
      loc = "pakke";
    }
    if (currPri) {
      firebase
        .database()
        .ref(`/${loc}/${itemId}`)
        .update({
          pri: false
        });
      document.getElementById(itemId + "pri").classList.add("invisible");
    } else {
      firebase
        .database()
        .ref(`/${loc}/${itemId}`)
        .update({
          pri: true
        });
      document.getElementById(itemId + "pri").classList.remove("invisible");
    }
  }

  undoItem(itemId) {
    const sendList = firebase.database().ref("pakke");
    const itemRef = firebase.database().ref(`/pakkeHistory/${itemId}`);
    itemRef.once("value", function(snapshot) {
      sendList.push(snapshot.val());
    });
    itemRef.remove();
  }

  deleteItemLog(itemId) {
    firebase
      .database()
      .ref(`/pakkeHistory/${itemId}`)
      .remove();
  }

  statusSort(status) {
    if (status !== this.state.sort) {
      this.setState({ sort: status, outside: false });
      if (status === 4) {
        this.setState({ outside: true });
      }
    } else {
      this.setState({ sort: 0, outside: false });
    }
  }

  renderBoolean(item) {
    if (item.status === 100) {
      return false;
    }
    if (
      this.state.sort === 0 ||
      (this.state.sort === 1 && item.status === 2) ||
      ((this.state.sort === 2 && (item.status === 1 || item.status === 0)) ||
        (this.state.sort === 3 && item.le === true))
    )
      return true;
  }

  changePakke(itemId, drive) {
    if (drive !== "") {
      this.changeFromUtkjoring(itemId, drive);
    } else {
      this.setState({
        pushId: itemId
      });
      this.openModal();
    }
  }

  changeFromUtkjoring(itemId, drive) {
    let itemRef = firebase.database().ref(`/utkjoring/${itemId}`);
    let pakkeRef = firebase.database().ref("pakke");
    itemRef.update({
      drive: "",
      address: ""
    });
    itemRef.once("value", function(snapshot) {
      pakkeRef.push(snapshot.val());
    });

    itemRef.remove();
  }
  changeToUtkjoring(itemId, drive, address, sms, date) {
    let itemRef = firebase.database().ref(`/pakke/${itemId}`);
    let utkjoringRef = firebase.database().ref("utkjoring");
    let pushDate = "Snarest";
    if (date !== "") {
      pushDate = date;
    }
    itemRef.update({
      drive: drive,
      address: address,
      sms: sms,
      date: pushDate,
      lat: this.state.lat,
      lng: this.state.lng
    });
    itemRef.once("value", function(snapshot) {
      utkjoringRef.push(snapshot.val());
    });
    itemRef.remove();
  }

  custRender() {
    return (
      <div>
        <div className="first" />
        <input
          type="text"
          name="name"
          placeholder="Navn"
          className="inputField"
          onChange={this.handleChange}
          value={this.state.name}
        />
        <br />
        <input
          type="tel"
          name="telephone"
          placeholder="Telefonnummer"
          className="inputField"
          onChange={this.handleChange}
          value={this.state.telephone}
        />
      </div>
    );
  }

  proRender() {
    return (
      <div>
        <div className="first" />
        <div className="dropdownField pakkeDrop">
          <Dropdown
            fluid
            search
            selection
            placeholder="Firma"
            className="inputField"
            onChange={this.handleDropdown}
            options={this.state.customers}
          />
        </div>
        <br />
        <input
          type="text"
          name="project"
          className="inputField"
          placeholder="Prosjekt"
          onChange={this.handleChange}
          value={this.state.project}
        />
        <br />
      </div>
    );
  }

  proClick() {
    this.setState({
      name: "",
      telephone: "",
      project: ""
    });
  }

  googlePlaces() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handlePlacesChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: "Søk etter adresse...",
                className: "inputField location-search-input"
              })}
            />
            <div className="autocomplete-dropdown-container">
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? "suggestionItem  activeSuggestion"
                  : "suggestionItem";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { cursor: "pointer" }
                  : { cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }

  modalGooglePlaces() {
    return (
      <PlacesAutocomplete
        value={this.state.addAddress}
        onChange={this.modalHandlePlacesChange}
        onSelect={this.modalHandleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: "Søk etter adresse...",
                className: "inputField location-search-input modalPlaces"
              })}
            />
            <div className="autocomplete-dropdown-container modalAuto">
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? "suggestionItem  activeSuggestion"
                  : "suggestionItem";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { cursor: "pointer" }
                  : { cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }

  disabledInput() {
    return (
      <div>
        <input
          type="text"
          name="address"
          placeholder="Søk etter adresse..."
          className="inputField"
          onChange={this.handleChange}
          value={this.state.address}
          disabled={this.state.disabledState}
        />
      </div>
    );
  }

  toggleHideLeft() {
    let leftDiv;
    if (this.state.outside) {
      leftDiv = document.getElementsByClassName("outsideInput");
    } else if (this.state.outside === false) {
      leftDiv = document.getElementsByClassName("leftApp pakkeLeft");
    }
    let rightDiv = document.getElementsByClassName("rightApp pakkeRight");
    let statusDiv = document.getElementsByClassName("statusOverview");
    document.getElementById("leftCaret").classList.toggle("hideIcon");
    document.getElementById("rightCaret").classList.toggle("hideIcon");

    $(document).ready(function() {
      let ldiv = $(leftDiv);
      let rdiv = $(rightDiv);
      let sdiv = $(statusDiv);
      ldiv.toggleClass("hiden");
      rdiv.toggleClass("widen");
      sdiv.toggleClass("heighten");
    });
  }

  confirmDelete(id, name, drive) {
    swalWithBootstrapButtons({
      title: "Slett",
      text: `Er du sikker på at du vil fjerne ${name} fra listen`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Ja",
      cancelButtonText: "Nei",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        this.deleteItem(id, drive);
        swalWithBootstrapButtons(
          "Slettet!",
          `${name} har blitt fjernet fra listen`,
          "success"
        );
      } else if (
        // Read more about handling dismissals
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons(
          "Handlingen ble avbrutt",
          `${name} ble ikke fjernet fra listen`,
          "error"
        );
      }
    });
  }

  confirmDeleteLog(id, name) {
    swalWithBootstrapButtons({
      title: "Slett",
      text: `Er du sikker på at du vil fjerne ${name} fra listen`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Ja",
      cancelButtonText: "Nei",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        this.deleteItemLog(id);
        swalWithBootstrapButtons(
          "Slettet!",
          `${name} har blitt fjernet fra listen`,
          "success"
        );
      } else if (
        // Read more about handling dismissals
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons(
          "Handlingen ble avbrutt",
          `${name} ble ikke fjernet fra listen`,
          "error"
        );
      }
    });
  }

  confirmLog(id, name, loc) {
    swalWithBootstrapButtons({
      title: "Utkjøringsvare!",
      text: `Er du sikker på at varen til ${name} er hentet, selv om den er merket som utkjøringsvare?`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Ja",
      cancelButtonText: "Nei",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        this.logItem(id, loc);
        swalWithBootstrapButtons(
          "Hentet!",
          `Varen til ${name} har blitt hentet`,
          "success"
        );
      } else if (
        // Read more about handling dismissals
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons(
          "Handlingen ble avbrutt",
          `Varen til ${name} ble ikke hentet`,
          "error"
        );
      }
    });
  }

  chooseItem = item => {
    this.setState({ chosen: item });
  };

  deleteOutsideItem = id => {
    this.setState({ chosen: null });
    this.deleteItem(id, "pakke");
  };

  unixToTime(timecode) {
    var timeVar = new Date(timecode);
    return timeVar.toLocaleString();
  }

  render() {
    return (
      <div className="appWindow">
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={modalStyle}
          contentLabel="Endre til utkjøringsvare"
        >
          <form onSubmit={this.submitModal}>
            <div className="modalHeader">ADRESSE</div>
            {this.modalGooglePlaces()}

            <div className="modalHeader">DATO</div>
            <input
              type="date"
              name="addDate"
              placeholder="Dato"
              className="modalInput"
              onChange={this.handleChange}
              value={this.state.addDate}
            />
            <br />

            <div className="modalHeader">KJØRES TIL</div>
            <select
              name="addDrive"
              className="modalDropdown"
              onChange={this.handleChange}
              value={this.state.addDrive}
            >
              <option value="Ballstad">Ballstad</option>
              <option value="Leknes">Leknes</option>
              <option value="Vest">Vest</option>
              <option value="Øst">Øst</option>
              <option value="Stamsund">Stamsund</option>
              <option value="Borge">Borge</option>
              <option value="Annet">Annet</option>
            </select>
            <br />
            <div className="smsPakke">
              <Checkbox
                checkboxClass="icheckbox_line-green"
                increaseArea="20%"
                name="addSms"
                checked={this.state.addSms}
                id="defaultCheck1"
                onChange={this.handleChange}
                label=" "
                insert={'<div class="icheck_line-icon"></div>SMS'}
              />
            </div>
            <button className="addBttn"> Legg til </button>
          </form>
        </Modal>
        {this.state.outside ? (
          <OutsideInput
            chosen={this.state.chosen}
            chosenFunc={this.deleteOutsideItem}
          />
        ) : (
          <div className="leftApp pakkeLeft">
            <h2 className="pakkeHeader">PAKKING</h2>
            <div className="fillPakke">
              <form onSubmit={this.handleSubmit} autoComplete="false">
                <div className="probuttonWrapper pakkeprobutton">
                  <Checkbox
                    checkboxClass="icheckbox_line-grey"
                    increaseArea="20%"
                    name="pro"
                    checked={this.state.pro}
                    id="defaultCheck1pro"
                    onChange={this.handleChange}
                    onClick={() => this.proClick()}
                    label=" "
                    insert={'<div class="icheck_line-icon"></div>Proff'}
                  />
                </div>
                {this.state.pro === false
                  ? this.custRender()
                  : this.proRender()}

                <input
                  type="number"
                  name="order"
                  placeholder="Ordre nr"
                  className="inputField"
                  onChange={this.handleChange}
                  value={this.state.order}
                />
                <br />

                <textarea
                  type="textarea"
                  name="comment"
                  placeholder="Kommentar vises kun til ansatte"
                  rows="1"
                  className="inputField"
                  onChange={this.handleChange}
                  value={this.state.comment}
                />
                <br />
                <div className="checkboxwrapperpakke">
                  <div className="smsboxwrapperpakke">
                    <Checkbox
                      checkboxClass="icheckbox_line-green"
                      increaseArea="20%"
                      name="le"
                      checked={this.state.le}
                      id="defaultCheck1"
                      onChange={this.handleChange}
                      label=" "
                      insert={'<div class="icheck_line-icon"></div>LE'}
                    />
                  </div>
                  <RadioGroup
                    name="delivery"
                    value={this.state.delivery}
                    onChange={this.handleRadioChange}
                  >
                    <Radio
                      value="option1"
                      radioClass="iradio_line-blue"
                      increaseArea="20%"
                      label=" "
                      insert={'<div class="icheck_line-icon"></div>Hentes'}
                    />
                    <Radio
                      value="option2"
                      radioClass="iradio_line-blue"
                      increaseArea="20%"
                      label=" "
                      insert={'<div class="icheck_line-icon"></div>Kjøres'}
                    />
                  </RadioGroup>
                </div>
                <br />

                {this.state.disabledState
                  ? this.disabledInput()
                  : this.googlePlaces()}

                <input
                  type="date"
                  name="date"
                  placeholder="Dato"
                  className="inputField"
                  onChange={this.handleChange}
                  value={this.state.date}
                  disabled={this.state.disabledState}
                />
                <br />

                <select
                  name="drive"
                  className="inputField"
                  onChange={this.handleChange}
                  value={this.state.drive}
                  disabled={this.state.disabledState}
                >
                  <option value="Ballstad">Ballstad</option>
                  <option value="Leknes">Leknes</option>
                  <option value="Vest">Vest</option>
                  <option value="Øst">Øst</option>
                  <option value="Stamsund">Stamsund</option>
                  <option value="Borge">Borge</option>
                  <option value="Annet">Annet</option>
                </select>
                <br />
                <div className="smsPakke">
                  <Checkbox
                    checkboxClass="icheckbox_line-green"
                    increaseArea="20%"
                    name="sms"
                    checked={this.state.sms}
                    id="defaultCheck1"
                    onChange={this.handleChange}
                    disabled={this.state.disabledState}
                    label=" "
                    insert={'<div class="icheck_line-icon"></div>SMS'}
                  />
                </div>
                <button className="addBttn"> Legg til </button>
              </form>
            </div>
            <div className="pakkeLoggWrapper">
              <div className="sortBox">
                <div className="utkjorbox nr">NR</div>
                <div className="utkjorbox name logg">NAVN</div>
                <div className="utkjorbox telephone logg">TELEFON</div>
                <div className="utkjorbox order logg">ORDRE</div>
                <div className="utkjorbox who">HVEM</div>
                <div className="utkjorbox car" />
                <div className="utkjorbox le" />
              </div>
              <div className="pakkeLogg" id="pakkeLogg">
                {this.state.logItems.map(item => {
                  return (
                    <li className="listBox1" id={item.id} key={item.id}>
                      <div className="utkjorbox nr">{item.count}</div>
                      <div className="utkjorbox name logg">{item.name}</div>
                      <div className="utkjorbox telephone logg">
                        {item.telephone}
                      </div>
                      <div className="utkjorbox order logg">{item.order}</div>
                      <div className="utkjorbox who">{item.person}</div>
                      <div className="utkjorbox car">
                        {item.drive !== "" ? (
                          <i className="fas fa-truck" />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="utkjorbox le">
                        {item.le === true ? "LE" : ""}
                      </div>
                      <div
                        className="undoIconWrapper logg"
                        onClick={() => this.undoItem(item.id)}
                      >
                        <i className="far fa-arrow-alt-circle-right" />
                      </div>
                      <div
                        className="removeIconWrapper logg"
                        onClick={() => {
                          this.confirmDeleteLog(item.id, item.name);
                        }}
                      >
                        <i className="far fa-times-circle removeIcon" />
                      </div>
                    </li>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        <div className="rightApp pakkeRight">
          <div className="statusOverview">
            <div className="hideButton" onClick={() => this.toggleHideLeft()}>
              <i class="fas fa-caret-left " id="leftCaret" />
              <i class="fas fa-caret-right hideIcon" id="rightCaret" />
            </div>
            <div
              className={
                "totalPakkeStatus greenStatus " +
                (this.state.sort === 1 ? "activeStatus" : "")
              }
              onClick={() => this.statusSort(1)}
            >
              {this.state.green}
            </div>
            <div
              className={
                "totalPakkeStatus orangeStatus " +
                (this.state.sort === 2 ? "activeStatus" : "")
              }
              onClick={() => this.statusSort(2)}
            >
              {this.state.orange}
            </div>
            <div
              className={
                "totalPakkeStatus blueStatus " +
                (this.state.sort === 3 ? "activeStatus" : "")
              }
              onClick={() => this.statusSort(3)}
            >
              {this.state.blue}
            </div>
            <div
              className={
                "totalPakkeStatus redStatus " +
                (this.state.sort === 4 ? "activeStatus" : "")
              }
              onClick={() => this.statusSort(4)}
            >
              {this.state.red}
            </div>
          </div>

          {this.state.outside ? (
            <OutsideList
              items={this.state.items}
              chosenFunc={this.chooseItem}
            />
          ) : (
            <div className="pakkeListPage">
              <div className="sortBox pakkeSort">
                <div>
                  <div className="utkjorbox nr">NR</div>
                  <div className="utkjorbox name pakke">NAVN</div>
                  <div className="utkjorbox telephone pakke">TELEFON</div>
                  <div className="utkjorbox order pakke">ORDRE</div>
                  <div className="utkjorbox status sortStatus">STATUS</div>
                  <div className="utkjorbox who">HVEM</div>
                  <div className="utkjorbox car" />
                  <div className="utkjorbox le" />
                  <div className="utkjorbox pri invisible" />
                </div>
              </div>
              <div className="pakkeList">
                {this.state.items.map(item => {
                  if (this.renderBoolean(item)) {
                    return (
                      <li
                        className={"listbox1 " + this.statusClass(item.status)}
                        id={item.id}
                        key={item.id}
                      >
                        <div className="utkjorbox nr">{item.count}</div>
                        <div className="utkjorbox name pakke">{item.name}</div>
                        <div className="utkjorbox telephone pakke">
                          {item.telephone}
                        </div>
                        <div className="utkjorbox order pakke">
                          {item.order}
                        </div>
                        <div
                          className={
                            "utkjorbox status " + this.statusClass(item.status)
                          }
                        >
                          <div
                            className={
                              item.status === 0 ? "showStatus" : "hideStatus"
                            }
                          >
                            <i className="far fa-circle" /> Ikke startet
                          </div>
                          <div
                            className={
                              item.status === 1 ? "showStatus" : "hideStatus"
                            }
                          >
                            <i className="far fa-clock" /> Pågår
                          </div>
                          <div
                            className={
                              item.status === 2 ? "showStatus" : "hideStatus"
                            }
                          >
                            <i className="far fa-check-circle" /> Ferdig
                          </div>
                        </div>
                        <div className="utkjorbox who">{item.person}</div>
                        <div className="utkjorbox car">
                          {item.drive !== "" ? (
                            <i className="fas fa-truck" />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="utkjorbox le">
                          {item.le === true ? "LE" : ""}
                        </div>
                        <div
                          className={
                            "utkjorbox pri " +
                            (item.pri === true ? "" : "invisible")
                          }
                          id={item.id + "pri"}
                        >
                          <i className="fas fa-flag" />
                        </div>

                        <div className="utkjorbox fromBestilling">
                          {item.count < 1000 ? (
                            <i className="fas fa-clipboard-list" />
                          ) : (
                            ""
                          )}
                        </div>

                        <button
                          className={
                            "sendBtn pakkeStatus " +
                            this.statusClass(item.status)
                          }
                          onClick={() =>
                            this.changeStatus(
                              item.id,
                              item.drive,
                              item.status,
                              item.name
                            )
                          }
                        >
                          {this.statusButton(item.status)}
                        </button>
                        <div
                          className="expandIcon pakke"
                          onClick={() => this.showComment(item.id)}
                        >
                          <i className="fas fa-plus" />
                        </div>
                        <div className="comment">
                          <div className="commentTextWrapper">
                            Kommentar: <br /> {item.comment}
                          </div>
                          <div className="linebetweenPakke" />
                          <div className="prosjektWrapper">
                            {item.pro ? "Prosjekt:" : ""}
                            <br />
                            {item.pro ? item.project : ""}
                          </div>
                          <div className="linebetweenPakke" />
                          <div className="utkjorbox kolli">
                            Kolli: <br /> {item.kolli}
                          </div>
                          <div className="linebetweenPakke" />
                          <div className="lagttilbox">
                            Lagt til av: <br />
                            <div className="commentWrapperDesc">
                            {item.addedBy} -{" "}
                            {item.addedByName !== null ? item.addedByName : ""}{" "}
                            <br />
                            {this.unixToTime(item.creation)}
                            </div>
                          </div>
                          <div className="linebetweenPakke" />
                          <div className="buttonWrapperpakke">
                          <button
                            className="sendBtn deleteBtn pakke"
                            onClick={() => {
                              this.confirmDelete(
                                item.id,
                                item.name,
                                item.drive
                              );
                            }}
                          >
                            <i class="fas fa-trash" />
                          </button>
                          <button
                            className={
                              "sendBtn deleteBtn pakke change " +
                              (item.drive !== "" ? "" : "changepakke")
                            }
                            onClick={() =>
                              this.changePakke(item.id, item.drive)
                            }
                          >
                            Utkjoring
                          </button>


                          <button
                            className="sendBtn deleteBtn pakke reset"
                            onClick={() => {
                              this.resetStatus(item.id, item.drive);
                            }}
                          >
                            Tilbakestill
                          </button>
                          </div>
                          <div className="buttonWrapperpakke2">
                          <button
                            className="sendBtn deleteBtn pakke pri"
                            onClick={() => {
                              this.prioritizeItem(
                                item.id,
                                item.drive,
                                item.pri
                              );
                            }}
                          >
                            Prioriter
                          </button>

                          {item.kolli > 0 ? (
                            <button
                              className="sendBtn deleteBtn pakke print"
                              onClick={() => {
                                this.printer(
                                  this.printerHelper(item.drive),
                                  item.id
                                );
                              }}
                            >
                              Print
                            </button>

                          ) : (
                            ""
                          )}
                          </div>
                        </div>
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Pakke;
