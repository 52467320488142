import React from "react";
import ReactDOM from "react-dom";
import "./utkjoring.css";
import firebase, { auth } from "./firebase.js";
import GoogleMapReact from "google-map-react";
import swal from "sweetalert2";
import { swalWithBootstrapButtons, swalAlert } from "./swalPopups.js";
var $ = require("jquery");
var _ = require("lodash");

const MapsPin = ({ text }) => (
  <div className="mapPin">
    <div className="mapPinText"> {text}</div>
    <div className="mapPinIcon">
      <i className="fas fa-map-marker" />
    </div>
  </div>
);

class Utkjoring extends React.Component {
  constructor() {
    super();

    this.state = {
      name: "",
      telephone: "",
      count: "",
      items: [],
      sendItems: [],
      historyItems: [],
      user: null,
      time: "",
      le: false,
      drive: "Ballstad",
      order: "",
      comment: "",
      date: "",
      sms: true,
      place: "",
      address: "",
      placeActive: "",
      showing: "send",
      places: [
        "Ballstad",
        "Leknes",
        "Vest",
        "Øst",
        "Stamsund",
        "Borge",
        "Annet"
      ],
      counter: [],
      mapPoints: [],
      center: {
        lat: 68.134598,
        lng: 13.608607
      },
      zoom: 11
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.name === "") {
      return;
    }
    if (this.state.telephone === "") {
      return;
    }
    if (this.state.drive === "") {
      return;
    }

    let itemsRef = firebase.database().ref("utkjoring");
    let countRef = firebase.database().ref("utkjoringcount");
    const item = {
      name: this.state.name,
      telephone: this.state.telephone,
      order: this.state.order,
      drive: this.state.drive,
      date: this.state.date,
      address: this.state.address,
      count: this.state.count,
      sms: this.state.sms,
      comment: this.state.comment,
      status: 0,
      le: this.state.le,
      pri: false
    };
    if (item.date === "") {
      item.date = "Snarest";
    }
    itemsRef.push(item);

    countRef.transaction(function(trans) {
      if (trans === 999) {
        return 500;
      } else {
        return trans + 1;
      }
    });

    this.setState({
      name: "",
      telephone: "",
      order: "",
      drive: "Ballstad",
      date: "",
      address: "",
      comment: "",
      sms: true,
      le: false
    });
  }

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (user) {
        this.setState({ user });
      }
    });
    this.retrieveData();
  }

  createMapOptions(maps) {
    return {
      styles: [
        { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }]
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }]
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#263c3f" }]
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }]
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#38414e" }]
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#212a37" }]
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }]
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#746855" }]
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#1f2835" }]
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#f3d19c" }]
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#2f3948" }]
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }]
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#17263c" }]
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#515c6d" }]
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#17263c" }]
        }
      ]
    };
  }

  retrieveData() {
    let itemsRef = firebase.database().ref("utkjoring");
    let sendRef = firebase.database().ref("utkjoringSend");
    let historyRef = firebase.database().ref("utkjoringHistory");
    let countRef = firebase.database().ref("utkjoringcount");
    countRef.on("value", snapshot => {
      let countsnap = snapshot.val();
      this.setState({
        count: countsnap
      });
    });

    itemsRef.on("value", snapshot => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          name: items[item].name,
          telephone: items[item].telephone,
          order: items[item].order,
          drive: items[item].drive,
          date: items[item].date,
          address: items[item].address,
          count: items[item].count,
          sms: items[item].sms,
          comment: items[item].comment,
          status: items[item].status,
          le: items[item].le,
          addedBy: items[item].addedBy,
          creation: items[item].creation,
          person: items[item].person
        });
      }

      newState.sort(function(a, b) {
        return a.count - b.count;
      });
      sendRef.on("value", snapshot => {
        let items = snapshot.val();
        let sendState = [];
        for (let item in items) {
          sendState.push({
            id: item,
            name: items[item].name,
            telephone: items[item].telephone,
            order: items[item].order,
            drive: items[item].drive,
            date: items[item].date,
            address: items[item].address,
            count: items[item].count,
            sms: items[item].sms,
            comment: items[item].comment,
            status: items[item].status,
            lat: items[item].lat,
            lng: items[item].lng,
            addedBy: items[item].addedBy,
            creation: items[item].creation,
            person: items[item].person
          });
        }
        sendState.sort(function(a, b) {
          return a.count - b.count;
        });

        historyRef.on("value", snapshot => {
          let items = snapshot.val();
          let historyState = [];
          for (let item in items) {
            historyState.push({
              id: item,
              name: items[item].name,
              telephone: items[item].telephone,
              order: items[item].order,
              group: items[item].group,
              drive: items[item].drive,
              date: items[item].date,
              address: items[item].address,
              count: items[item].count,
              sms: items[item].sms,
              comment: items[item].comment,
              status: items[item].status,
              lat: items[item].lat,
              lng: items[item].lng,
              addedBy: items[item].addedBy,
              creation: items[item].creation,
              person: items[item].person
            });
          }

          historyState.sort(function(a, b) {
            return b.group - a.group;
          });

          let groupHistory = _.groupBy(historyState, item => item.group);

          let places = this.state.places;
          let pushArray = [];
          for (let place in places) {
            let currPlace = places[place];
            let countArray = this.statusCount(places[place], newState);
            pushArray.push({
              name: currPlace,
              green: countArray[0],
              orange: countArray[1],
              blue: countArray[2],
              red: countArray[3]
            });
          }

          this.setState({
            items: newState,
            sendItems: sendState,
            historyItems: groupHistory,
            counter: pushArray
          });
        });
      });
    });
  }

  showSend() {
    this.setState({
      showing: "send",
      historyMaps: []
    });
  }
  showHistory() {
    this.setState({
      showing: "history",
      historyMaps: []
    });
  }

  sendItem(itemId, status) {
    if (status !== 2) {
      swalAlert({
        text: "Varen er ikke ferdig pakket!"
      });
      return;
    }
    const sendList = firebase.database().ref("utkjoringSend");
    const itemRef = firebase.database().ref(`/utkjoring/${itemId}`);
    itemRef.once("value", function(snapshot) {
      sendList.push(snapshot.val());
    });
    firebase
      .database()
      .ref(`/utkjoring/${itemId}`)
      .remove();
  }

  unsendItem(itemId) {
    const sendList = firebase.database().ref("utkjoring");
    const itemRef = firebase.database().ref(`/utkjoringSend/${itemId}`);
    itemRef.once("value", function(snapshot) {
      sendList.push(snapshot.val());
    });
    firebase
      .database()
      .ref(`/utkjoringSend/${itemId}`)
      .remove();
  }

  sortPlace(place) {
    this.setState({
      placeActive: place
    });
    let div = document.getElementById("placeOverview").children;
    for (var i = 0; i < div.length; i++) {
      if (div[i].classList.contains("placeActive")) {
        div[i].classList.remove("placeActive");
      }
    }
    document.getElementById(place).classList.add("placeActive");
  }

  historyItem(itemId, groupCount) {
    let itemRef = firebase.database().ref(`/utkjoringSend/${itemId}`);
    let nestedList = firebase.database().ref(`/utkjoringHistory`);

    itemRef.update({ group: groupCount });

    itemRef.once("value", function(snapshot) {
      nestedList.push(snapshot.val());
    });
    firebase
      .database()
      .ref(`/utkjoringSend/${itemId}`)
      .remove();
  }

  sendAll() {
    let groupNr = Date.now();
    for (var i = 0; i < this.state.sendItems.length; i++) {
      this.historyItem(this.state.sendItems[i].id, groupNr);
    }
  }

  clearSendList() {
    for (var i = 0; i < this.state.sendItems.length; i++) {
      this.unsendItem(this.state.sendItems[i].id);
    }
  }

  toggleSms(itemId) {
    const smsState = firebase.database().ref(`/utkjoringSend/${itemId}/sms`);
    let iconDiv = document.getElementById(itemId + "sms");
    ReactDOM.unmountComponentAtNode(iconDiv);
    smsState.once("value", function(snapshot) {
      if (snapshot.val() === true) {
        smsState.set(false);
        iconDiv.classList.add("offSms");
      } else {
        smsState.set(true);
        iconDiv.classList.remove("offSms");
      }
    });
  }

  smsIconStatus(smsState, divId) {
    let onIcon = <i class="far fa-envelope onSms" />;
    let offIcon = <i class="far fa-envelope offSms" />;

    if (smsState) {
      return onIcon;
    } else {
      return offIcon;
    }
  }

  showComment(divid) {
    let div = document.getElementById(divid).children[9];
    let icon = document.getElementById(divid).children[8];
    $(document).ready(function() {
      let jdiv = $(div);
      let jicon = $(icon);
      jdiv.slideToggle(300).css("display", "block");
      jicon.find("svg").toggleClass("fa-minus fa-plus");
    });
  }

  deleteItem(itemId) {
    firebase
      .database()
      .ref(`/utkjoring/${itemId}`)
      .remove();
  }

  renderHelper() {
    if (this.state.showing === "send") {
      return (
        <div className="sendList">
          <div className="sortBox sendSort">
            <div>
              <div className="utkjorbox nr">NR</div>
              <div className="utkjorbox name">NAVN</div>
              <div className="utkjorbox telephone">TELEFON</div>
              <div className="utkjorbox order">ORDRE</div>
              <div className="utkjorbox address">ADRESSE</div>
              <div className="utkjorbox date">STED</div>
            </div>
          </div>
          <div className="listSend">
            {this.state.sendItems.map(item => {
              return (
                <li className="listBox" id={item.id} key={item.id}>
                  <div className="utkjorbox nr">{item.count}</div>
                  <div className="utkjorbox name">{item.name}</div>
                  <div className="utkjorbox telephone">{item.telephone}</div>
                  <div className="utkjorbox order">{item.order}</div>
                  <div className="utkjorbox address">{item.address}</div>
                  <div className="utkjorbox date">{item.drive}</div>
                  <div
                    className={"smsIcon " + (item.sms === true ? "" : "offSms")}
                    onClick={() => this.toggleSms(item.id)}
                    id={item.id + "sms"}
                  >
                    <i className="far fa-envelope" />
                  </div>

                  <div
                    className="removIconWrapper"
                    onClick={() => this.unsendItem(item.id)}
                  >
                    <i className="far fa-times-circle removeIcon" />
                  </div>
                </li>
              );
            })}
          </div>
          <div className="sendMenu">
            Totalt: {this.state.sendItems.length}
            <button
              className="sendBtn clear"
              onClick={() => this.clearSendList()}
            >
              Fjern
            </button>
            <button className="sendBtn sendAll" onClick={() => this.sendAll()}>
              Kjør
            </button>
          </div>
        </div>
      );
    } else if (this.state.showing === "history") {
      let returnVar = [];
      for (let group in this.state.historyItems) {
        returnVar.push(this.state.historyItems[group]);
      }
      return (
        <div className="historyList">
          {returnVar.map(group => {
            let groupDate = new Date(group[0].group);
            let date =
              ("0" + groupDate.getHours()).slice(-2) +
              ":" +
              ("0" + groupDate.getMinutes()).slice(-2) +
              " " +
              groupDate.getDate() +
              "/" +
              (groupDate.getMonth() + 1) +
              "-" +
              groupDate.getFullYear();

            return (
              <div className="listbox" key={group[0].group + "box"}>
                <div className="historyDate">{date}</div>
                <div className="historyNumber">
                  Antall utkjørte: {group.length}
                </div>

                <div
                  className="historyIcon"
                  id={group[0].group + "icon"}
                  onClick={() => this.showHistoryElements(group[0].group)}
                >
                  <i className="fas fa-plus" />
                </div>

                <div
                  className="historyIcon mapIcon"
                  onClick={() => this.addToMap(group[0].group)}
                >
                  <i className="fas fa-map" />
                </div>

                <div className="historyItemWrapper" id={group[0].group}>
                  <div className="historyItemElement elementCount">NR</div>
                  <div className="historyItemElement elementName">NAVN</div>
                  <div className="historyItemElement elementTelephone">
                    TELEFON
                  </div>
                  <div className="historyItemElement elementOrder">ORDRE</div>
                  <div className="historyItemElement elementAddress">
                    ADRESSE
                  </div>
                  <div className="historyItemElement elementDrive">
                    KJØRT TIL
                  </div>
                  {group.map(item => {
                    return (
                      <div className="historyItem" key={item.count + "history"}>
                        <div className="historyItemElement elementCount">
                          {item.count}
                        </div>
                        <div className="historyItemElement elementName">
                          {item.name}
                        </div>
                        <div className="historyItemElement elementTelephone">
                          {item.telephone}
                        </div>
                        <div className="historyItemElement elementOrder">
                          {item.order}
                        </div>
                        <div className="historyItemElement elementAddress">
                          {item.address}
                        </div>
                        <div className="historyItemElement elementDrive">
                          {item.drive}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }

  showHistoryElements(id) {
    let div = document.getElementById(id);
    let icon = document.getElementById(id + "icon");
    $(document).ready(function() {
      var jdiv = $(div);
      var jicon = $(icon);
      jdiv.slideToggle(300).css("display", "block");
      jicon.find("svg").toggleClass("fa-minus fa-plus");
    });
  }

  statusCount(place, items) {
    let green = 0;
    let orange = 0;
    let blue = 0;
    let red = 0;

    for (let item in items) {
      if (items[item].drive === place) {
        if (items[item].status === 2) {
          green++;
        }
        if (items[item].status === 1) {
          orange++;
        }
        if (items[item].status === 0) {
          orange++;
        }
        if (items[item].le === true) {
          blue++;
        }
      }
    }
    return [green, orange, blue, red];
  }

  statusCountPlace(place) {
    let value = [];
    this.state.counter.forEach(x => {
      if (x.name === place) {
        value = [x.green, x.orange, x.blue, x.red];
      }
    });
    return value;
  }

  statusDivRender(array) {
    return (
      <div>
        <div className="statusCount green">{array[0]}</div>
        <div className="statusCount orange">{array[1]}</div>
        <div className="statusCount blue">{array[2]}</div>
        <div className="statusCount red">{array[3]}</div>
      </div>
    );
  }

  circleStatus(status) {
    if (status === 0 || status === 1) {
      return "orange";
    } else {
      return "green";
    }
  }

  returnPins() {
    let returnBox = [];
    for (let el in this.state.sendItems) {
      let textElement = this.state.sendItems[el].count;
      returnBox.push(
        <MapsPin
          key={this.state.sendItems[el].id}
          lat={this.state.sendItems[el].lat}
          lng={this.state.sendItems[el].lng}
          text={textElement}
        />
      );
    }
    return returnBox;
  }

  returnHistoryPins() {
    let returnBox = [];

    for (let el in this.state.historyMaps) {
      let textElement = this.state.historyMaps[el].count;
      returnBox.push(
        <MapsPin
          lat={this.state.historyMaps[el].lat}
          lng={this.state.historyMaps[el].lng}
          text={textElement}
        />
      );
    }
    return returnBox;
  }

  addToMap(groupId) {
    for (let el in this.state.historyItems) {
      if (groupId === this.state.historyItems[el][0].group) {
        this.setState({ historyMaps: this.state.historyItems[el] });
      }
    }
  }

  confirmDelete(id, name) {
    swalWithBootstrapButtons({
      title: "Slett",
      text: `Er du sikker på at du vil fjerne ${name} fra listen`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Ja",
      cancelButtonText: "Nei",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        this.deleteItem(id);
        swalWithBootstrapButtons(
          "Slettet!",
          `${name} har blitt fjernet fra listen`,
          "success"
        );
      } else if (
        // Read more about handling dismissals
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons(
          "Handlingen ble avbrutt",
          `${name} ble ikke fjernet fra listen`,
          "error"
        );
      }
    });
  }

  unixToTime(timecode) {
    var timeVar = new Date(timecode);
    return timeVar.toLocaleString();
  }

  render() {
    return (
      <div className="appWindow">
        <div className="leftApp">
          <div className="addPage">
            <div className="mapsWrapper">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyAPkxp-QV5OSeDIwTZMpI8iJVPjxr8VJ6g"
                }}
                defaultCenter={this.state.center}
                defaultZoom={this.state.zoom}
                options={this.createMapOptions}
              >
                {this.state.showing === "send"
                  ? this.returnPins()
                  : this.returnHistoryPins()}
              </GoogleMapReact>
            </div>
          </div>
          <div className="historyPage">
            <div className="historyHeaderWrapper">
              <div className="sendHeader" onClick={() => this.showSend()}>
                Send Ut
              </div>
              <div className="historyHeader" onClick={() => this.showHistory()}>
                Historikk
              </div>
            </div>
            {this.renderHelper()}
          </div>
        </div>
        <div className="rightApp">
          <div className="overview" id="placeOverview">
            <div
              active
              className="place"
              id="Ballstad"
              onClick={() => this.sortPlace("Ballstad")}
            >
              Ballstad
              <div className="statusWrapper">
                {this.statusDivRender(this.statusCountPlace("Ballstad"))}
              </div>
            </div>
            <div
              className="place"
              id="Leknes"
              onClick={() => this.sortPlace("Leknes")}
            >
              Leknes
              <div className="statusWrapper">
                {this.statusDivRender(this.statusCountPlace("Leknes"))}
              </div>
            </div>
            <div
              className="place"
              id="Vest"
              onClick={() => this.sortPlace("Vest")}
            >
              Vest
              <div className="statusWrapper">
                {this.statusDivRender(this.statusCountPlace("Vest"))}
              </div>
            </div>
            <div
              className="place"
              id="Øst"
              onClick={() => this.sortPlace("Øst")}
            >
              Øst
              <div className="statusWrapper">
                {this.statusDivRender(this.statusCountPlace("Øst"))}
              </div>
            </div>
            <div
              className="place"
              id="Stamsund"
              onClick={() => this.sortPlace("Stamsund")}
            >
              Stamsund
              <div className="statusWrapper">
                {this.statusDivRender(this.statusCountPlace("Stamsund"))}
              </div>
            </div>
            <div
              className="place"
              id="Borge"
              onClick={() => this.sortPlace("Borge")}
            >
              Borge
              <div className="statusWrapper">
                {this.statusDivRender(this.statusCountPlace("Borge"))}
              </div>
            </div>
            <div className="place" id="LE" onClick={() => this.sortPlace("LE")}>
              LE
            </div>
            <div
              className="place"
              id="Annet"
              onClick={() => this.sortPlace("Annet")}
            >
              Annet
              <div className="statusWrapper">
                {this.statusDivRender(this.statusCountPlace("Annet"))}
              </div>
            </div>
          </div>
          <div className="listPage">
            <div className="utkjoringList">
              <div className="sortBox">
                <div>
                  <div className="utkjorbox nr"> NR</div>
                  <div className="utkjorbox name">NAVN</div>
                  <div className="utkjorbox telephone">TELEFON</div>
                  <div className="utkjorbox order sortOrder">ORDRE</div>
                  <div className="utkjorbox address sortAddress">ADRESSE</div>
                  <div className="utkjorbox date">DATO</div>
                </div>
              </div>
              <div className="listUtkjoring">
                {this.state.items.map(item => {
                  if (
                    item.drive === this.state.placeActive ||
                    (item.le && this.state.placeActive === "LE")
                  ) {
                    return (
                      <li
                        className={"listbox " + this.circleStatus(item.status)}
                        id={item.id}
                        key={item.id}
                      >
                        <div className="utkjorbox nr">{item.count}</div>
                        <div className="utkjorbox name">{item.name}</div>
                        <div className="utkjorbox telephone">
                          {item.telephone}
                        </div>
                        <div className="utkjorbox order">{item.order}</div>
                        <div className="utkjorbox address">{item.address}</div>
                        <div className="utkjorbox date">{item.date}</div>
                        <div className="utkjorbox le">
                          {item.le === true ? "LE" : ""}
                        </div>
                        <button
                          className="sendBtn utkjor"
                          onClick={() => this.sendItem(item.id, item.status)}
                        >
                          Send
                        </button>
                        <div
                          className="expandIcon"
                          onClick={() => this.showComment(item.id)}
                        >
                          <i className="fas fa-plus" />
                        </div>
                        <div className="comment">
                          <div className="commentTextWrapper">
                            Kommentar: <br /> {item.comment}
                          </div>
                          <div className="commentTextWrapper">
                            Pakket av: <br /> {item.person}
                          </div>
                          <div className="commentTextWrapper">
                            Lagt til Av: <br />
                            {item.addedBy} -{" "}
                            {item.addedByName !== null ? item.addedByName : ""}{" "}
                            <br />
                            {this.unixToTime(item.creation)}
                          </div>
                          <button
                            className="sendBtn deleteBtn utkjoringDelete"
                            onClick={() => {
                              this.confirmDelete(item.id, item.name);
                            }}
                          >
                            <i className="fas fa-trash" />
                          </button>
                        </div>
                      </li>
                    );
                  } else {
                    return "";
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Utkjoring;
