import firebase from 'firebase';
var config = {
  apiKey: "AIzaSyCwVlFPYLko1LelPUb1fNYreVmt1wdenMY",
  authDomain: "levare-7bd7b.firebaseapp.com",
  databaseURL: "https://levare-7bd7b.firebaseio.com",
  projectId: "levare-7bd7b",
  storageBucket: "levare-7bd7b.appspot.com",
  messagingSenderId: "550237099259"
};
firebase.initializeApp(config);

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();

export default firebase;
