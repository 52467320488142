import React from "react";
import ReactDOM from "react-dom";
import { Dropdown } from "semantic-ui-react";
import { Checkbox, Radio, RadioGroup } from "react-icheck";
import firebase, { auth } from "./firebase.js";
import printlabel from "./printlabel.js";
import "icheck/skins/all.css";
import printlabelV from "./printlabel-v.js";
import Modal from "react-modal";
import swal from "sweetalert2";
import { swalWithBootstrapButtons, Toast } from "./swalPopups.js";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

const Dymo = require("dymojs"),
  dymo = new Dymo();
var $ = require("jquery");

const modalStyle = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "25px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

class Levare extends React.Component {
  constructor() {
    super();

    this.state = {
      name: "",
      telephone: "",
      kolli: "",
      count: "",
      items: [],
      retrievedItems: [],
      user: null,
      time: "",
      comment: "",
      sms: true,
      print: "option1",
      printer: "option2",
      description: "",
      pro: false,
      project: "",
      userId: "",
      modalIsOpen: false,
      addDrive: "Ballstad",
      address: "",
      addDate: "",
      addSms: false,
      pushId: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDropdown = this.handleDropdown.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.submitModal = this.submitModal.bind(this);
    this.handlePlacesChange = this.handlePlacesChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    // Sets the `signedIn` state property to `true` once signed in.
    callbacks: {
      signInSuccess: () => {
        this.setState({ signedIn: true });
        this.retrieveData();
        return false; // Avoid redirects after sign-in.
      }
    }
  };

  handlePlacesChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({ lat: latLng.lat, lng: latLng.lng }))
      .catch(error => console.error("Error", error));
  };

  openModal(id) {
    this.setState({
      modalIsOpen: true,
      pushId: id
    });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
      addSms: false,
      address: "",
      addDrive: "Ballstad",
      addDate: "",
      pushId: ""
    });
  }

  submitModal(e) {
    e.preventDefault();
    this.changeToUtkjoring(
      this.state.pushId,
      this.state.address,
      this.state.addDate,
      this.state.addDrive,
      this.state.addSms
    );
    this.closeModal();
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleRadioChange(event, value) {
    const target = event.target;
    const name = target.name;
    this.setState({ [name]: value });

    if (name === "printer") {
      let userId = this.state.userId;
      let userRef = firebase.database().ref("users/" + userId);
      userRef.set({ [name]: value });
    }
  }

  handleDropdown(event, data) {
    let temp;
    for (let i in data.options) {
      if (data.value === data.options[i].name) {
        temp = data.options[i].telephone;
      }
    }
    this.setState({
      name: data.value,
      telephone: temp
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.name === "") {
      return;
    }
    if (this.state.telephone === "") {
      return;
    }
    if (this.state.kolli === "") {
      return;
    }
    const itemsRef = firebase.database().ref("items");
    const countRef = firebase.database().ref("count");
    let addedByVar;
    if (this.state.printer === "option1") {
      addedByVar = "Butikk";
    } else {
      addedByVar = "Lager";
    }
    const item = {
      name: this.state.name,
      telephone: this.state.telephone,
      kolli: this.state.kolli,
      count: this.state.count,
      creation: Date.now(),
      comment: this.state.comment,
      removedAt: "",
      sms: this.state.sms,
      description: this.state.description,
      pro: this.state.pro,
      project: this.state.project,
      from: this.state.printer,
      addedBy: addedByVar,
      addedByName: this.state.user.displayName,
      fakturer: false
    };
    let pushId = itemsRef.push(item).getKey();

    countRef.transaction(function(trans) {
      if (trans === 999) {
        return 100;
      } else {
        return trans + 1;
      }
    });

    Toast.fire({
      type: "success",
      title: "Vare ble lagt til"
    });

    this.printer(
      this.state.count,
      this.state.name,
      this.state.telephone,
      this.state.kolli,
      this.state.print,
      pushId
    );

    this.setState({
      name: "",
      telephone: "",
      kolli: "",
      comment: "",
      sms: true,
      print: "option1",
      pro: false,
      description: "",
      project: ""
    });
  }

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (user) {
        this.setState({ user });
      }
    });
    this.retrieveData();
  }

  printerCheck() {
    if (this.state.printer === "option1") {
      return "DYMO LabelWriter 450";
    } else if (this.state.printer === "option2") {
      return "DYMO LabelWriter 450 Twin Turbo";
    }
  }

  printer(count, name, telephone, kolli, orientation, id) {
    let printerString = this.printerCheck();
    if (printerString === "DYMO LabelWriter 450") {
      if (orientation === "option1") {
        for (let i = 1; i <= kolli; i++) {
          dymo.print(
            printerString,
            printlabel(count, name, telephone, i, kolli, id),
            ``
          );
        }
      } else {
        for (let i = 1; i <= kolli; i++) {
          dymo.print(
            printerString,
            printlabelV(count, name, telephone, i, kolli, id),
            ``
          );
        }
      }
    } else {
      if (orientation === "option1") {
        for (let i = 1; i <= kolli; i++) {
          dymo.print(
            printerString,
            printlabel(count, name, telephone, i, kolli, id),
            `<LabelWriterPrintParams><TwinTurboRoll>Right</TwinTurboRoll></LabelWriterPrintParams>`
          );
        }
      } else {
        for (let i = 1; i <= kolli; i++) {
          dymo.print(
            printerString,
            printlabelV(count, name, telephone, i, kolli, id),
            `<LabelWriterPrintParams><TwinTurboRoll>Right</TwinTurboRoll></LabelWriterPrintParams>`
          );
        }
      }
    }
  }

  removeItem(itemId) {
    const retrievedList = firebase.database().ref("retrievedItems");
    const itemRef = firebase.database().ref(`/items/${itemId}`);
    itemRef.update({ removedAt: Date.now() });
    itemRef.once("value", function(snapshot) {
      retrievedList.push(snapshot.val());
    });
    firebase
      .database()
      .ref(`/items/${itemId}`)
      .remove();
  }

  deleteItem(itemId) {
    this.addToDeleteFolder(itemId, "items");
    firebase
      .database()
      .ref(`/items/${itemId}`)
      .remove();
  }

  deleteRetrievedItem(itemId) {
    this.addToDeleteFolder(itemId, "retrievedItems");
    firebase
      .database()
      .ref(`/retrievedItems/${itemId}`)
      .remove();
  }

  addToDeleteFolder(itemId, loc) {
    let deleteFolder = firebase.database().ref("deleteLevare");
    let itemRef = firebase.database().ref(`/${loc}/${itemId}`);

    itemRef.once("value", function(snapshot) {
      deleteFolder.push(snapshot.val());
    });
  }

  recoverItem(itemId, name) {
    swalWithBootstrapButtons({
      title: `Slett`,
      text: `Er du sikker på at du vil gjenopprett ${name} fra listen`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Ja",
      cancelButtonText: "Nei",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        const retrievedList = firebase.database().ref("items");
        const itemRef = firebase.database().ref(`/retrievedItems/${itemId}`);
        itemRef.once("value", function(snapshot) {
          retrievedList.push(snapshot.val());
        });
        firebase
          .database()
          .ref(`/retrievedItems/${itemId}`)
          .remove();
        swalWithBootstrapButtons(
          "Slettet!",
          `${name} har blitt gjenopprettet fra listen`,
          "success"
        );
      } else if (
        // Read more about handling dismissals
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons(
          "Handlingen ble avbrutt",
          `${name} ble ikke gjenopprettet fra listen`,
          "error"
        );
      }
    });

  }

  showComment(divid) {
    /*10th child is the div for comment, 0 index */
    var div = document.getElementById(divid).children[9];
    var icon = document.getElementById(divid).children[7];
    $(document).ready(function() {
      var jdiv = $(div);
      var jicon = $(icon);
      jdiv.slideToggle(300).css("display", "block");
      jicon.find("svg").toggleClass("fa-minus fa-plus");
    });
  }

  showCommentRet(divid) {
    /*9th child is the div for comment, 0 index */
    var div = document.getElementById(divid).children[9];
    var icon = document.getElementById(divid).children[7];
    $(document).ready(function() {
      var jdiv = $(div);
      var jicon = $(icon);
      jdiv.slideToggle(300).css("display", "block");
      jicon.find("svg").toggleClass("fa-minus fa-plus");
    });
  }

  editComment(divid, value) {
    var div = document.getElementById(divid).children[9].children[1]
      .children[0];
    this.setState({ editComment: value }, () => {
      ReactDOM.render(this.editBox(value, divid, div), div);
    });
  }

  editBox(placevalue, itemId, remove) {
    return (
      <div className="editBox">
        <form>
          <textarea
            name="editComment"
            rows="3"
            className="editCommentBox"
            onChange={this.handleChange}
            defaultValue={this.state.editComment}
          />
        </form>
        <button
          className="removebtn save"
          onClick={() => this.saveComment(itemId, remove)}
        >
          Lagre
        </button>
      </div>
    );
  }

  saveComment(itemId, div) {
    firebase
      .database()
      .ref(`/items/${itemId}/comment`)
      .set(this.state.editComment);
    ReactDOM.unmountComponentAtNode(div);
    ReactDOM.render(this.state.editComment, div);
    this.setState({ editComment: "" });
  }

  smsStatusReturn(smsBoo) {
    if (smsBoo === true) {
      return "SMS: På";
    } else {
      return "SMS: Av";
    }
  }

  smsChange(itemId) {
    let smsState = firebase.database().ref(`/items/${itemId}/sms`);
    smsState.once("value", function(snapshot) {
      if (snapshot.val() === true) {
        smsState.set(false);
      } else {
        smsState.set(true);
      }
    });
  }

  dateCalculate(days) {
    return Math.floor((Date.now() - days) / (24 * 60 * 60 * 1000));
  }

  colorChange(days) {
    if (this.dateCalculate(days) >= 10 && this.dateCalculate(days) <= 13) {
      return "orange";
    }
    if (this.dateCalculate(days) >= 14) {
      return "red";
    }
    return "";
  }

  retrieveData() {
    const itemsRef = firebase.database().ref("/items");
    const retrievedList = firebase.database().ref("retrievedItems");
    const countRef = firebase.database().ref("count");
    const proffRef = firebase.database().ref("proffKunder");
    let userId = firebase.auth().currentUser.uid;
    this.setState({
      userId: userId
    });
    let userRef = firebase.database().ref("users/" + userId);

    userRef.on("value", snapshot => {
      let printSnap = snapshot.val();
      if (printSnap !== null) {
        this.setState({
          printer: printSnap.printer
        });
      } else {
        userRef.set({
          printer: "option2"
        });
      }
    });

    countRef.on("value", snapshot => {
      let countsnap = snapshot.val();
      this.setState({
        count: countsnap
      });
    });
    itemsRef.on("value", snapshot => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          name: items[item].name,
          telephone: items[item].telephone,
          kolli: items[item].kolli,
          count: items[item].count,
          creation: items[item].creation,
          comment: items[item].comment,
          sms: items[item].sms,
          description: items[item].description,
          pro: items[item].pro,
          project: items[item].project,
          addedBy: items[item].addedBy,
          addedByName: items[item].addedByName,
          fakturer: items[item].fakturer
        });
      }
      newState.sort(function(a, b) {
        return a.creation - b.creation;
      });
      this.setState({
        items: newState
      });
    });

    proffRef.on("value", snapshot => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          name: items[item].name,
          telephone: items[item].telephone,
          key: items[item].name,
          value: items[item].name,
          text: items[item].name
        });
      }

      this.setState({
        customers: newState
      });
    });

    retrievedList.limitToLast(15).on("value", snapshot => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          name: items[item].name,
          telephone: items[item].telephone,
          kolli: items[item].kolli,
          count: items[item].count,
          creation: items[item].creation,
          comment: items[item].comment,
          removedAt: items[item].removedAt,
          description: items[item].description,
          addedBy: items[item].addedBy,
          addedByName: items[item].addedByName
        });
      }
      newState.sort(function(a, b) {
        return b.removedAt - a.removedAt;
      });
      this.setState({
        retrievedItems: newState
      });
    });
  }

  custRender() {
    return (
      <div>
        <div className="nameHeader" />
        <input
          type="text"
          name="name"
          placeholder="Navn"
          onChange={this.handleChange}
          value={this.state.name}
        />

        <br />

        <input
          type="tel"
          name="telephone"
          placeholder="Telefonnummer"
          onChange={this.handleChange}
          value={this.state.telephone}
        />
        <br />
      </div>
    );
  }

  proRender() {
    return (
      <div>
        <div className="nameHeader" />
        <div className="dropdownField">
          <Dropdown
            fluid
            placeholder="Firma"
            search
            selection
            onChange={this.handleDropdown}
            options={this.state.customers}
          />
        </div>
        <br />

        <input
          type="text"
          name="project"
          placeholder="Prosjekt"
          onChange={this.handleChange}
          value={this.state.project}
        />
        <br />
      </div>
    );
  }

  proClick() {
    this.setState({
      name: "",
      telephone: "",
      project: ""
    });
  }

  changeToUtkjoring(id, address, date, drive, sms) {
    let itemRef = firebase.database().ref(`/items/${id}`);
    let utkjoringRef = firebase.database().ref("utkjoring");
    let pushDate = "Snarest";
    if (date !== "") {
      pushDate = date;
    }
    itemRef.update({
      drive: drive,
      address: address.substr(0, this.state.address.indexOf(",")),
      sms: sms,
      date: pushDate,
      pri: false,
      le: false,
      status: 0,
      lat: this.state.lat,
      lng: this.state.lng
    });

    itemRef.once("value", function(snapshot) {
      utkjoringRef.push(snapshot.val());
    });
    itemRef.remove();
  }

  modalGooglePlaces() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handlePlacesChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: "Søk etter adresse...",
                className: "inputField location-search-input modalPlaces"
              })}
            />
            <div className="autocomplete-dropdown-container modalAuto">
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? "suggestionItem  activeSuggestion"
                  : "suggestionItem";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { cursor: "pointer" }
                  : { cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }

  fakturer(id) {
    let fakturerState = firebase.database().ref(`/items/${id}/fakturer`);
    fakturerState.once("value", function(snapshot) {
      if (snapshot.val() === true) {
        fakturerState.set(false);
      } else {
        fakturerState.set(true);
      }
    });
  }

  confirmDelete(id, name) {
    swalWithBootstrapButtons({
      title: `Slett`,
      text: `Er du sikker på at du vil fjerne ${name} fra listen`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Ja",
      cancelButtonText: "Nei",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        this.deleteItem(id);
        swalWithBootstrapButtons(
          "Slettet!",
          `${name} har blitt fjernet fra listen`,
          "success"
        );
      } else if (
        // Read more about handling dismissals
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons(
          "Handlingen ble avbrutt",
          `${name} ble ikke fjernet fra listen`,
          "error"
        );
      }
    });
  }

  confirmDeleteRetrieve(id, name) {
    swalWithBootstrapButtons({
      title: "SLETT",
      text: `Er du sikker på at du vil fjerne ${name} fra listen`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Ja",
      cancelButtonText: "Nei",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        this.deleteRetrievedItem(id);
        swalWithBootstrapButtons(
          "Slettet!",
          `${name} har blitt fjernet fra listen`,
          "success"
        );
      } else if (
        // Read more about handling dismissals
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons(
          "Handlingen ble avbrutt",
          `${name} ble ikke fjernet fra listen`,
          "error"
        );
      }
    });
  }

  confirmRemove(id, name, fakturertState) {
    if (fakturertState) {
      this.removeItem(id);
    }
    swalWithBootstrapButtons({
      title: "Fakturert?",
      text: `Har varen til ${name} blitt fakturert?`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Ja",
      cancelButtonText: "Nei",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        this.removeItem(id);
        swalWithBootstrapButtons(
          "Hentet!",
          `${name} sin vare er blitt hentet`,
          "success"
        );
      } else if (
        // Read more about handling dismissals
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons(
          "Sjekk om varen er fakturert",
          `${name} ble ikke fjernet fra listen`,
          "error"
        );
      }
    });
  }

  unixToTime(timecode) {
    var timeVar = new Date(timecode);
    return timeVar.toLocaleString();
  }

  render() {
    return (
      <div>
        <div className="appwindow">
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={modalStyle}
            contentLabel="Endre til utkjøringsvare"
          >
            <form onSubmit={this.submitModal}>
              <div className="modalHeader">ADRESSE</div>
              {this.modalGooglePlaces()}
              <div className="modalHeader">DATO</div>
              <input
                type="date"
                name="addDate"
                placeholder="Dato"
                className="modalInput"
                onChange={this.handleChange}
                value={this.state.addDate}
              />
              <br />
              <div className="modalHeader">KJØRES TIL</div>
              <select
                name="addDrive"
                className="modalDropdown"
                onChange={this.handleChange}
                value={this.state.addDrive}
              >
                <option value="Ballstad">Ballstad</option>
                <option value="Leknes">Leknes</option>
                <option value="Vest">Vest</option>
                <option value="Øst">Øst</option>
                <option value="Stamsund">Stamsund</option>
                <option value="Borge">Borge</option>
                <option value="Annet">Annet</option>
              </select>
              <br />
              <div className="smsPakke">
                <Checkbox
                  checkboxClass="icheckbox_line-green"
                  increaseArea="20%"
                  name="addSms"
                  checked={this.state.addSms}
                  id="defaultCheck1"
                  onChange={this.handleChange}
                  label=" "
                  insert={'<div class="icheck_line-icon"></div>SMS'}
                />
              </div>
              <button className="addBttn"> Legg til </button>
            </form>
          </Modal>
          <div className="add-page">
            <div className="add-box">
              <h2>BESTILTE VARER</h2>
              <ul className="infotekst">
                <li>- Sjekk om varen er fakturert før du leverer den ut</li>
                <li>- 1.varsel etter 4 dager, kl 11 / proff 7</li>
                <li>- 2.varsel etter 8 dager, kl 11 / proff 14</li>
                <li>- Siste frist for henting 13 dager / proff 20 </li>
                <li>- Kommenter hvis det er avtalt noe annet med kunden</li>
              </ul>
              <div className="fill">
                <form onSubmit={this.handleSubmit}>
                  <div className="probuttonWrapper">
                    <Checkbox
                      checkboxClass="icheckbox_line-grey"
                      increaseArea="20%"
                      name="pro"
                      checked={this.state.pro}
                      id="defaultCheck1pro"
                      onChange={this.handleChange}
                      onClick={() => this.proClick()}
                      label=" "
                      insert={'<div class="icheck_line-icon"></div>Proff'}
                    />
                  </div>

                    <div className="anleggbuttonWrapper">
                      <Checkbox
                        disabled
                        checkboxClass="icheckbox_line-grey"
                        increaseArea="20%"
                        name="pro"
                        //checked={this.state.pro}
                        id="defaultCheck1pro"
                        onChange={this.handleChange}
                        onClick={() => this.proClick()}
                        label=" "
                        insert={'<div class="icheck_line-icon"></div>Anlegg'}
                      />
                  </div>
                  <br />

                  <div className="checkboxwrapper">
                    <div className="smsboxwrapper">
                      <Checkbox
                        checkboxClass="icheckbox_line-green"
                        increaseArea="20%"
                        name="sms"
                        checked={this.state.sms}
                        id="defaultCheck1"
                        onChange={this.handleChange}
                        label=" "
                        insert={'<div class="icheck_line-icon"></div>SMS'}
                      />
                    </div>
                    <RadioGroup
                      name="print"
                      value={this.state.print}
                      onChange={this.handleRadioChange}
                    >
                      <Radio
                        value="option1"
                        radioClass="iradio_line-blue"
                        increaseArea="20%"
                        label=" "
                        insert={
                          '<div class="icheck_line-icon"></div>Horisontal'
                        }
                      />
                      <Radio
                        value="option2"
                        radioClass="iradio_line-blue"
                        increaseArea="20%"
                        label=" "
                        insert={'<div class="icheck_line-icon"></div>Vertikal'}
                      />
                    </RadioGroup>
                  </div>
                  {this.state.pro === false
                    ? this.custRender()
                    : this.proRender()}

                  <input
                    type="number"
                    name="kolli"
                    min="1"
                    max="25"
                    placeholder="Antall kolli"
                    onChange={this.handleChange}
                    value={this.state.kolli}
                  />
                  <br />

                  <input
                    type="text"
                    name="description"
                    placeholder="Beskrivelse av bestilling"
                    onChange={this.handleChange}
                    value={this.state.description}
                  />

                  <br />
                  <div className="kommentarbox" />
                  <textarea
                    type="textarea"
                    name="comment"
                    placeholder="Kommentarer vises kun til de ansatte"
                    rows="1"
                    onChange={this.handleChange}
                    value={this.state.comment}
                  />

                  <br />
                  <button className="addbttn"> Legg til </button>
                </form>
              </div>
            </div>

            <div className="retrievedlist-page">
              <div className="sortbox sortretrieve">
                <div className="boxcount">NR</div>
                <div className="boxname">NAVN</div>
                <div className="boxtlf">TLF</div>
                <div className="boxkolli">KOLLI</div>
                <div className="boxdays">DAGER</div>
              </div>
              <ol className="listofitems itemsretrieved">
                {this.state.retrievedItems.map(item => {
                  return (
                    <li key={item.id}>
                      <div className="listbox" id={item.id} key={item.id}>
                        <div className="boxcount">{item.count}</div>
                        <div className="boxname">{item.name} </div>{" "}
                        <div className="boxtlf">{item.telephone} </div>{" "}
                        <div className="boxkolli">{item.kolli}</div>{" "}
                        <div className="boxdays">
                          {this.dateCalculate(item.creation)}
                        </div>
                        <button
                          className="recover"
                          onClick={() => this.recoverItem(item.id, item.name)}
                        >
                          Gjenopprett
                        </button>
                        <button
                          className="removebtn feilbtn retrieveremove"
                          onClick={() => {
                            this.confirmDeleteRetrieve(item.id, item.name);
                          }}
                        >
                          <i className="fas fa-trash" />
                        </button>
                        <div
                          className="showIcon showRet"
                          onClick={() => this.showCommentRet(item.id)}
                        >
                          <i className="fas fa-plus" />
                        </div>
                        <br />
                        <div className="comment">
                          <div className="linebetweenEksp" />
                          <div className="commentText">
                            <div className="commentHeader retrieved">
                              Kommentar: <br />{" "}
                              <div className="commentWrapper">
                                {item.comment}
                              </div>
                            </div>
                            <div className="linebetween" />

                            <div className="descriptionText retrieved">
                              Beskrivelse: <br />{" "}
                              <div className="commentWrapperDesc">
                                {item.description}
                              </div>
                            </div>
                            <div className="linebetween" />
                            <div className="descriptionText addText">
                              Lagt til Av: <br />
                              <div className="commentWrapperDesc">
                                {item.addedBy} -{" "}
                                {item.addedByName !== null
                                  ? item.addedByName
                                  : ""}
                                <br />
                                {this.unixToTime(item.creation)}
                              </div>
                            </div>
                            <div className="linebetween" />
                            <div className="descriptionText retrieved">
                              Hentet: <br />
                              <div className="commentWrapperDesc">{this.unixToTime(item.removedAt)}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>

          <div className="list-page">
            <div className="sortbox">
              <div className="boxcount">NR</div>
              <div className="boxname">NAVN</div>
              <div className="boxtlf">TLF</div>
              <div className="boxkolli">KOLLI</div>
              <div className="boxdays">DAGER</div>
            </div>
            <ol className="listofitems" id="sorttable">
              {this.state.items.map(item => {
                return [
                  <li key={item.id}>
                    <div
                      className={"listbox " + this.colorChange(item.creation)}
                      id={item.id}
                      key={item.id}
                    >
                      <div
                        className={
                          "boxcount " + this.colorChange(item.creation)
                        }
                      >
                        {item.count}
                      </div>
                      <div className="boxname">{item.name} </div>{" "}
                      <div className="boxtlf">{item.telephone} </div>{" "}
                      <div className="boxkolli">{item.kolli}</div>{" "}
                      <div className="boxdays">
                        {this.dateCalculate(item.creation)}
                      </div>
                      <button
                        className="removebtn"
                        onClick={() =>
                          this.confirmRemove(item.id, item.name, item.fakturer)
                        }
                      >
                        Hentet
                      </button>
                      <button
                        className="removebtn feilbtn"
                        onClick={() => {
                          this.confirmDelete(item.id, item.name);
                        }}
                      >
                        <i className="fas fa-trash" />
                      </button>
                      <div
                        className="showIcon"
                        onClick={() => this.showComment(item.id)}
                      >
                        <i className="fas fa-plus" />
                      </div>
                      <br />
                      <div className="comment">
                        <div className="linebetweenEksp" />
                        <div className="commentText">
                          <div
                            className="editIconWrapper"
                            onClick={() =>
                              this.editComment(item.id, item.comment)
                            }
                          >
                            <i
                              className="far fa-edit editIcon"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="commentHeader">
                            Kommentar: <br />
                            <div className="commentWrapper">{item.comment}</div>
                          </div>
                          <div className="linebetween" />
                          <div className="descriptionText">
                            Beskrivelse: <br />{" "}
                            <div className="commentWrapperDesc">
                              {" "}
                              {item.description}{" "}
                            </div>
                          </div>
                          <div className="linebetween" />
                          <div className="descriptionText addText">
                            Lagt til Av: <br />
                            <div className="commentWrapperDesc">
                              {item.addedBy} -{" "}
                              {item.addedByName !== null
                                ? item.addedByName
                                : ""}{" "}
                              <br />
                              {this.unixToTime(item.creation)}
                            </div>
                          </div>
                          <div className="linebetween" />
                          <div className="descriptionText projectText">
                            {item.pro ? "Prosjekt:" : ""}
                            <br />
                            {item.project}
                          </div>
                          <div className="linebetween" />

                          <div className="buttonWrapper">
                            <button
                              className={
                                "removebtn utkjoring fakturer " +
                                (item.fakturer === true ? "fakturerActive" : "")
                              }
                              onClick={() => this.fakturer(item.id)}
                            >
                              Fakturer
                            </button>

                            <button
                              className="removebtn utkjoring"
                              onClick={() => this.openModal(item.id)}
                            >
                              Utkjøring
                            </button>
                          </div>
                          <div className="buttonWrapper2">
                            <button
                              className="removebtn lars"
                              onClick={() => this.smsChange(item.id)}
                            >
                              {this.smsStatusReturn(item.sms)}
                            </button>
                            <button
                              className="removebtn print"
                              onClick={() =>
                                this.printer(
                                  item.count,
                                  item.name,
                                  item.telephone,
                                  item.kolli,
                                  "option1",
                                  item.id
                                )
                              }
                            >
                              Print
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ];
              })}
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

export default Levare;
