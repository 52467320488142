import React, { Component } from "react";
import "./ChosenItemField.css";
export default class ChosenItemField extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="chosenItemField">
        <div className="chosenItemHeader">{this.props.name}</div>
        <div className="chosenItemData">{this.props.itemData}</div>
      </div>
    );
  }
}
