import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Switch
} from "react-router-dom";
import { Dropdown, Checkbox } from "semantic-ui-react";
import Loadable from "react-loadable";
import Levare from "./levare.js";
import MediaQuery from "react-responsive";
import Utkjoring from "./utkjoring.js";
import Pakke from "./pakke.js";
import logo from "./logo.png";
import ByggtorgetLogo from "./byggtorgetHvit.png";
import loginBG from "./bg.jpg";
import ReloadDiv from "./ReloadDiv.js";
import TilbudsVarer from "./TilbudsVarer.js";
import "./App.css";

import firebase, { auth, provider } from "./firebase.js";
import { FirebaseAuth } from "react-firebaseui";

const Loading = () => <div>Laster...</div>;

const MobileView = Loadable({
  loader: () => import("./MobileView.js"),
  loading: Loading,
  delay: 300
});

const Feedback = Loadable({
  loader: () => import("./Feedback.js"),
  loading: Loading,
  delay: 300
});

const SendSms = Loadable({
  loader: () => import("./sendsms.js"),
  loading: Loading,
  delay: 300
});

const Gps = Loadable({
  loader: () => import("./Gps.js"),
  loading: Loading,
  delay: 300
});

const Restsalg = Loadable({
  loader: () => import("./restsalg.js"),
  loading: Loading,
  delay: 300
});

const Proff = Loadable({
  loader: () => import("./Proff.js"),
  loading: Loading,
  delay: 300
});

const Oversikt = Loadable({
  loader: () => import("./Oversikt.js"),
  loading: Loading,
  delay: 300
});

class App extends Component {
  constructor() {
    super();
    this.state = {
      user: null,
      time: "",
      sms: true,
      where: false,
      uid: "",
      printer: "option2"
    };
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }
  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    // Sets the `signedIn` state property to `true` once signed in.
    callbacks: {
      signInSuccessWithAuthResult: () => {
        this.setState({ signedIn: true });
        this.retrieveData();
        return false; // Avoid redirects after sign-in.
      }
    }
  };

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleRadioChange(event, value) {
    this.setState({ print: value });
  }

  handleCheckbox(event) {
    let userId = this.state.uid;
    let userRef = firebase.database().ref("users/" + userId);

    if (this.state.where === false) {
      userRef.set({ printer: "option2" });
      this.setState({ where: true });
    } else {
      userRef.set({ printer: "option1" });
      this.setState({ where: false });
    }
  }

  handleSubmit(e) {}

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (user) {
        this.setState({ user });
        this.setState({ uid: user.uid });
        this.retrieveData();
      }
    });
  }

  handleEmail(email, password) {
    auth.signInWithEmailAndPassword(email, password);
  }

  login() {
    auth.signInWithPopup(provider).then(result => {
      const user = result.user;
      this.setState({
        user
      });
    });
  }

  logout() {
    auth.signOut().then(() => {
      this.setState({
        user: null
      });
    });
  }

  retrieveData() {
    let userRef = firebase.database().ref("users/" + this.state.uid);

    userRef.on("value", snapshot => {
      let printSnap = snapshot.val();
      if (printSnap !== null) {
        this.setState({
          printer: printSnap.printer
        });
      } else {
        userRef.set({
          printer: "option2"
        });
      }
      if (this.state.printer === "option1") {
        this.setState({ where: false });
      } else {
        this.setState({ where: true });
      }
    });
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/tilbudsvarer" component={TilbudsVarer} />
          <Route path="/">
            <div className="App">
              {this.state.user ? (
                <header className="App-header">
                  <img src={logo} className="App-logo" alt="logo" />
                  <img
                    src={ByggtorgetLogo}
                    className="App-byggtorget"
                    alt="Byggtorget"
                  />
                  <div className="headerWrapper">
                    <div className="currentUserLogin">
                      {this.state.user.displayName}
                    </div>
                    <div className="settingsCog">
                      <Dropdown
                        className="icon"
                        icon="setting"
                        closeOnBlur={false}
                        closeOnChange={false}
                        text=" "
                        direction="left"
                        options={[
                          {
                            key: "settings",
                            description: "Butikk | Lager",
                            text: (
                              <Checkbox
                                toggle
                                onClick={this.handleCheckbox}
                                checked={this.state.where}
                              />
                            )
                          },
                          {
                            key: "sign-out",
                            onClick: this.logout,
                            text: "Logg Ut"
                          }
                        ]}
                      />
                    </div>

                    {/* <button onClick={this.logout} className="App-logu">
                    Logg Ut
                  </button> */}
                  </div>
                </header>
              ) : (
                ""
              )}

              {this.state.user ? (
                <div className="mainPage">
                  <ReloadDiv />
                  <MediaQuery query="(min-device-width: 1224px)">
                    <div className="sidebar">
                      <div className="linkWrapper">
                        <div className="linkTo">
                          <NavLink
                            exact={true}
                            className="links"
                            activeClassName="activeLink"
                            to="/"
                          >
                            <div className="sidebarElement">
                              <div className="sidebarIcon">
                                <i className="fas fa-list fa-sm" />
                              </div>
                            </div>
                          </NavLink>
                        </div>

                        <div className="linkTo">
                          <NavLink
                            className="links"
                            activeClassName="activeLink"
                            to="/utkjoring"
                          >
                            <div className="sidebarElement">
                              <div className="sidebarIcon">
                                <i className="fas fa-truck fa-xs" />
                              </div>
                            </div>
                          </NavLink>
                        </div>
                        <div className="linkTo">
                          <NavLink
                            className="links"
                            activeClassName="activeLink"
                            to="/pakkestatus"
                          >
                            <div className="sidebarElement">
                              <div className="sidebarIcon">
                                <i className="fas fa-cube fa-sm" />
                              </div>
                            </div>
                          </NavLink>
                        </div>

                        <div className="linkTo">
                          <NavLink
                            className="links"
                            activeClassName="activeLink"
                            to="/restsalg"
                          >
                            <div className="sidebarElement">
                              <div className="sidebarIcon">
                                <i className="fas fa-percent fa-xs" />
                              </div>
                            </div>
                          </NavLink>
                        </div>
                        <div className="linkTo">
                          <NavLink
                            className="links"
                            activeClassName="activeLink"
                            to="/sendsms"
                          >
                            <div className="sidebarElement">
                              <div className="sidebarIcon">
                                <i className="fas fa-envelope fa-sm" />
                              </div>
                            </div>
                          </NavLink>
                        </div>
                        <div className="linkTo">
                          <NavLink
                            className="links"
                            activeClassName="activeLink"
                            to="/gps"
                          >
                            <div className="sidebarElement">
                              <div className="sidebarIcon">
                                <i className="fas fa-map-marker-alt fa-xs" />
                              </div>
                            </div>
                          </NavLink>
                        </div>
                        <div className="linkTo">
                          <NavLink
                            className="links"
                            activeClassName="activeLink"
                            to="/proff"
                          >
                            <div className="sidebarElement">
                              <div className="sidebarIcon">
                                <i className="fas fa-user fa-xs" />
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      </div>

                      <div className="linkWrapper2">
                        <div className="linkTo2">
                          <NavLink
                            className="links"
                            activeClassName="activeLink"
                            to="/feedback"
                          >
                            <div className="sidebarElement">
                              <div className="sidebarIcon">
                                <i className="far fa-comments fa-xs" />
                              </div>
                            </div>
                          </NavLink>
                        </div>
                        <div className="linkTo2">
                          <NavLink
                            className="links"
                            activeClassName="activeLink"
                            to="/admin"
                          >
                            <div className="sidebarElement">
                              <div className="sidebarIcon">
                                <i className="fas fa-lock fa-xs" />
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div className="renderWindow">
                      <Route exact path="/" component={Levare} />
                      <Route path="/sendsms" component={SendSms} />
                      <Route path="/utkjoring" component={Utkjoring} />
                      <Route path="/pakkestatus" component={Pakke} />
                      <Route path="/restsalg" component={Restsalg} />
                      <Route path="/gps" component={Gps} />
                      <Route path="/proff" component={Proff} />
                      <Route path="/oversikt" component={Oversikt} />
                      <Route path="/feedback" component={Feedback} />
                    </div>
                  </MediaQuery>

                  <MediaQuery query="(max-width: 1224px)">
                    <MobileView />
                  </MediaQuery>
                </div>
              ) : (
                <div className="loginWrapper">
                  <MediaQuery query="(min-device-width: 1224px)">
                    <img src={loginBG} className="loginBG" alt="bg" />
                    <img src={logo} className="loginLogo" alt="logo" />
                  </MediaQuery>

                  <MediaQuery query="(max-width: 1224px)">
                    <img src={logo} className="loginLogo mobile" alt="logo" />
                    <img src={loginBG} className="loginBG mobile" alt="bg" />
                  </MediaQuery>

                  <div className="firebaseAuthWrapper">
                    <FirebaseAuth
                      uiConfig={this.uiConfig}
                      firebaseAuth={firebase.auth()}
                    />
                  </div>
                </div>
              )}
            </div>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
