import React from "react";
import firebase from "./firebase.js";
import ChosenItemField from "./ChosenItemField.js";
import "./OutsideInput.css";
import { Checkbox} from 'react-icheck';
import swal from "sweetalert2";
import { swalWithBootstrapButtons } from "./swalPopups.js";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
const searchOptions = {
  componentRestrictions: {country: "no" }
  // Notat for bedre avgrensing til autocomplete
  // 68.273714, 13.930509
  // location: {east: 14.5, north: 69, south: 68, west: 13},
  // radius: 61000,
  // types: ['address']
};

class OutsideInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chosenItem: props.chosen,
      drive: "Ballstad",
      sms:true,
      transport: true,
      le: false,
      lat: "",
      lng: "",
      date: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePlacesChange = this.handlePlacesChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  componentWillReceiveProps(nextProps) {
    this.clearInput();
  }

  handleSubmit(e) {
    e.preventDefault();
    if(this.state.transport && this.state.address === "" && this.state.lat === ""){
      return
    }
    let tempName = this.inputCompare(this.props.chosen.name, this.state.name);
    let tempTlph = this.inputCompare(this.props.chosen.telephone, this.state.telephone);
    swalWithBootstrapButtons({
      title: `Legge til ny?`,
      html: `Er du sikker på at du vil legge til følgende objekt? <br/> ${
        tempName
      } <br/> ${this.state.address} <br/> ${tempTlph}`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Ja",
      cancelButtonText: "Nei",
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        this.handleItem();
        swalWithBootstrapButtons(
          "Lagt til!",
          `${tempName} ble lagt till i listen`,
          "success"
        );
        this.clearInput();
        this.props.chosenFunc(this.props.chosen.id);
      } else if (
        // Read more about handling dismissals
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons(
          "Handlingen ble avbrutt",
          `${tempName} ble ikke lagt til i listen`,
          "error"
        );
      }
    });
  }

  handleItem(){
    let countRef = firebase.database().ref("utkjoringcount");
    countRef.on("value", snapshot => {
      let countsnap = snapshot.val();
      this.setState({
        count: countsnap
      });
    });
    let item = {
      name: this.inputCompare(this.props.chosen.name, this.state.name),
      order: this.inputCompare(this.props.chosen.order, this.state.order),
      telephone: this.inputCompare(this.props.chosen.telephone, this.state.telephone),
      status: 0,
      le: this.state.le,
      pri: false,
      count: this.state.count,
      date: this.state.date,
      sms:this.state.sms
    };
    if(this.state.transport){
      item["lat"] = this.state.lat;
      item["lng"] = this.state.lng;
      item["drive"] = this.state.drive;
      item["sms"] = this.state.sms;
      item["address"] = this.state.address.substr(0, this.state.address.indexOf(","));
    }
    if (item.date === "") {
        item.date = "Snarest";
    }
    if(this.state.transport){
      let utkjoringRef = firebase.database().ref("utkjoring");
      utkjoringRef.push(item);
    } else {
      let pakkeRef = firebase.database().ref("pakke");
      pakkeRef.push(item);
    }
    countRef.transaction(function(trans) {
      if (trans === 999) {
        return 500;
      } else {
        return trans + 1;
      }
    });

  }

  handlePlacesChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    console.log(address);
    
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({ lat: latLng.lat, lng: latLng.lng }))
      .catch(error => console.error("Error", error));
    this.setState({address});
  };

  googlePlaces() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handlePlacesChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: "Søk etter adressen over...",
                className: "inputField location-search-input outside"
              })}
            />
            <div className="autocomplete-dropdown-container outside">
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? "suggestionItem  activeSuggestion"
                  : "suggestionItem";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { cursor: "pointer" }
                  : { cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }

  inputCompare(preChosen, inputValue) {
    if(inputValue.length > 0){
      return inputValue;
    } else {
      return preChosen;
    }
    /**
     * Compare value for chosen to value in input field
     *
     *
     *
     */
  }

  emptyInput(value) {
    /**
     * Helper function. If empty input field for specific value, then true.
     * Use for additional class in upperInfo fields, to colorize
     */
  }

  clearInput() {
    this.setState({
      name: "",
      address: "",
      order: "",
      telephone: "",
      drive: "Ballstad",
      lat: "",
      lng: ""
    });
  }

  render() {
    return (
      <div className="outsideInput">
        <div className="upperInfo">
          <div className="upperInfoHeader">Ordre</div>
          {this.props.chosen === null ? (
            " "
          ) : (
            <div className="upperInfoFields">
              <ChosenItemField name="Navn" itemData={this.props.chosen.name} />
              <ChosenItemField
                name="Ordre"
                itemData={this.props.chosen.order}
              />
              <ChosenItemField
                name="Adresse"
                itemData={this.props.chosen.address}
              />
              <ChosenItemField
                name="Postnummer/sted"
                itemData={this.props.chosen.address2}
              />
              <ChosenItemField
                name="Telefonnummer"
                itemData={this.props.chosen.telephone}
              />
              <ChosenItemField name="Dato" itemData={this.props.chosen.date} />
              <ChosenItemField name="Transport" itemData="True" />
            </div>
          )}
        </div>
        <div className="outsideInputForm">
          {this.props.chosen === null ? (
            " "
          ) : (
            <form onSubmit={this.handleSubmit} autoComplete="false" className="outsideInputFormWrapper">
              <div className="outsideInputField">
                <div className="outsideInputHeader">Navn</div>
                <input
                  type="text"
                  name="name"
                  placeholder={this.props.chosen.name}
                  className="inputField outside"
                  onChange={this.handleChange}
                  value={this.state.name}
                />
              </div>

              <div className="outsideInputField">
                <div className="outsideInputHeader">Ordrenummer</div>
                <input
                  type="text"
                  name="order"
                  placeholder={this.props.chosen.order}
                  className="inputField outside"
                  onChange={this.handleChange}
                  value={this.state.order}
                />
              </div>

              <div className="outsideInputField">
                <div className="outsideInputHeader">Addresse</div>
                {this.googlePlaces()}
              </div>

              <div className="outsideInputField">
                <div className="outsideInputHeader">Telefonnumer</div>
                <input
                  type="tel"
                  name="telephone"
                  placeholder={this.props.chosen.telephone}
                  className="inputField outside"
                  onChange={this.handleChange}
                  value={this.state.telephone}
                />
              </div>

              <div className="outsideInputField">
                <div className="outsideInputHeader">Kjøres til</div>
                <select
                  name="drive"
                  className="inputField outside"
                  onChange={this.handleChange}
                  value={this.state.drive}
                >
                  <option value="Ballstad">Ballstad</option>
                  <option value="Leknes">Leknes</option>
                  <option value="Vest">Vest</option>
                  <option value="Øst">Øst</option>
                  <option value="Stamsund">Stamsund</option>
                  <option value="Borge">Borge</option>
                  <option value="Annet">Annet</option>
                </select>
              </div>

              <div className="outsideInputField">
                <div className="outsideInputHeader">Dato for utkjøring</div>
                <input
                  type="date"
                  name="date"
                  placeholder="Date"
                  className="inputField outside"
                  onChange={this.handleChange}
                  value={this.state.date}
                />
              </div>

              <div className="outsideButtonWrapper">
                      <Checkbox
                        checkboxClass="icheckbox_line-green"
                        increaseArea="20%"
                        name="sms"
                        checked={this.state.sms}
                        id="defaultCheck1"
                        onChange={this.handleChange}
                        label=" "
                        insert={'<div class="icheck_line-icon"></div>SMS'}
                      />

                    <Checkbox
                      checkboxClass="icheckbox_line-green"
                      increaseArea="20%"
                      name="transport"
                      checked={this.state.transport}
                      id="defaultCheck1"
                      onChange={this.handleChange}
                      label=" "
                      insert={'<div class="icheck_line-icon"></div>Kjøres'}
                    />

                    <Checkbox
                      checkboxClass="icheckbox_line-green"
                      increaseArea="20%"
                      name="le"
                      checked={this.state.le}
                      id="defaultCheck1"
                      onChange={this.handleChange}
                      label=" "
                      insert={'<div class="icheck_line-icon"></div>LE'}
                    />
              </div>

              <button className="addBttn outside">Legg til</button>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default OutsideInput;
