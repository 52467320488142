import React from "react";
import "./ReloadDiv.css";

class ReloadModal extends React.Component {
  state = {
    show: false
  };

  componentDidMount() {
    // Handle global event.
    window.addEventListener("newContentAvailable", () => {
      this.setState({
        show: true
      });
    });
  }

  onClick = () => {
    // Reload when modal click.
    window.location.reload(true);
  };
  render() {
    if (!this.state.show) {
      return null;
    }
    // <Modal> is common fixed component.
    return (
      <div className="reloadDiv" onClick={this.onClick}>
        <span> Siden er oppdatert, klikk her for å oppdatere. </span>
      </div>
    );
  }
}

export default ReloadModal;
